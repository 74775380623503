import AlertDialog from "../../shared/AlertDialog";
import React, { useState, useEffect, useRef } from "react";
import Button from "react-bootstrap/Button";
import axios from "axios";
import {
  PMA_DETAILS,
  ZOOMMEETING_DETAILS,
  ADD_PMA_DETAILS,
  INSERT_DECISION_FILE,
  HTML_TO_PDF,
  GET_DECISION_FILEWITHSAS,
  DOCUSIGN_CONNECTOR,
  GET_DOCUSIGNFILE,
  GET_PENDINGCOUNTFORNEUTRAL,
  MERGE_MULTIPLE_PDF,
  DELETE_RESPONSE,
  DELETE_PMA_RESPONSE,
  DELETE_PMA_ADDITIONAL_RESPONSE,
  DELETE_ADDITIONAL_RESPONSE,
  GRAMERLY_CLIENT_ID,
  GRAMERLY_ASSERTION_ID,
  mailTimesheet,
  EDIT_NEUTRAL_EMAIL,
  isMask,
  env,
  MERGE_DECISIONPMA_RESPONSE,
  isSyncBack,
  voidedDocusign,
  HEARINGDETAILS,
  ADD_PMA_DETAILS_FOR_MASS_FILE,
  MERGE_MULTIPLE_PDF_FOR_MASS_FILE,
} from "../../api/baseURL";
import { get } from "../../api/api";
import LoadingOverlay from "react-loading-overlay";
import * as Utility from "../../utils/utility";
import FileUploader from "f1-file-upload-react";
import "./pma.scss";
import "./decision.scss";
import NumberFormat from "react-number-format";
import F1GrammarCheckerTextArea from "f1-grammar-enabled-textarea-react";

import DecisionTemplate from "./decisionTemplate";
import CaseData from "./caseData";
import Modal from "react-bootstrap/Modal";
// Plugins
import { Worker, Viewer } from "@react-pdf-viewer/core";
import { defaultLayoutPlugin } from "@react-pdf-viewer/default-layout";
import { decryptItem, encryptItem } from "../../shared/Encrypt";
import { useSelector } from "react-redux";
import ApplyDecisionTemplateCases from "./ApplyDecisionTemplateCases";
import PreviewESignDocumentsForSelectedCases from "./PreviewESignDocumentsForSelectedCases";
document
  .querySelectorAll("grammarly-editor-plugin")
  .forEach((grammarlyEditor) => {
    grammarlyEditor.config = {
      oauthAssertionProvider: async () => {
        //// Call your endpoint to get an assertion
        const response = await fetch(GRAMERLY_ASSERTION_ID, {
          method: "POST",
          mode: "cors",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            clientId: GRAMERLY_CLIENT_ID,
          }),
        });
        /// Handle errors
        if (!response.ok) {
          throw new Error("Error creating assertion");
        }
        //// Return generated assertion
        return await response.json();
      },
    };
  });

export default function Decision(props) {
  const defaultInstance = defaultLayoutPlugin();
  const dateObj = new Date();
  //const CurrentDate = dateObj.getDate();
  const hearingCode = parseInt(
    props.hearingCode ? props.hearingCode : props.match.params?.hearingCode
  );
  const [amountError, setIsAmountError] = useState(false);
  const [plaintiffError, setIsPaintiffError] = useState(false);
  const [defendantError, setIsdefendantNameError] = useState(false);
  const [isLoaded, setIsLoaded] = useState(true);
  const [currentIndex, setCurrentIndex] = useState(-1);
  const [isdisplayAlertError, setdisplayAlertError] = useState(false);
  const [isNewFormSubmission, setisNewFormSubmission] = useState(true);
  const [decisionFileAvailable, setdecisionFileAvailable] = useState(false);
  const [isFileSendForDocusign, setisFileSendForDocusign] = useState(false);
  const [submitRefresh, setSubmitRefresh] = useState(0);
  const [isShowDeleteMessage, setisShowDeleteMessage] = useState(true);
  const [HearingOfficerEmailPMA, setHearingOfficerEmail] = useState(
    props.caseDetails["hearingOfficerEmail"]
  );
  const [btnHandle, setBtnHandle] = useState(false);
  const [plainTiffDefentError, setPlainTiffDefentError] = useState({});
  const [attendies, setattendies] = useState(props.attendies);
  const [hilightDecisionDetails, sethilightDecisionDetails] = useState(false);
  const [hilightDefendent, setHilightDefendent] = useState(false);
  const [hilightPlantiff, sethilightPlantiff] = useState(false);
  const [pmaDetails, setPMADetails] = useState({});
  const [isDocuSigned, SetisDocuSigned] = useState(false);
  const [pmaAdditionalNotes] = useState({});
  const [pmaNoLater] = useState({});
  const [isFileUpload, setIsFileUpload] = useState({});
  const [isFormSubmit, setIsFormSubmit] = useState(true);
  const [errorMessage, setErrorMessage] = useState("");
  const [message, setMessage] = useState("");
  const [isSubmitted, setIsSubmitted] = useState({});
  const [isPreview, setIsPreview] = useState(false);
  const [caseUnsettled, setcaseUnsettled] = useState(false);
  const [isPreviewResponse, setIsPreviewResponse] = useState(false);
  const [PreviewResponse, setPreviewResponse] = useState("");
  const [response, setresponse] = useState(null);
  const [switchUploads, setSwitchUploads] = useState(false);
  const [initialAdditionalFiles, setInitialAdditionalFiles] = useState(null);
  const [markAsPmaFile, setMarkAsPmaFile] = useState(null);
  const [markAsPmaUploadFile, setMarkAsPmaUploadFile] = useState(null);
  const [markAsPmaDecisioFile, setMarkAsPmaDecisioFile] = useState(null);
  const [uploaderrorMessage, setUploadErrorMessage] = useState("");
  const [uploaderrorMessage1, setUploadErrorMessage1] = useState("");
  const [isuploaderrorMessage, setisUploadErrorMessage] = useState(false);
  const [isuploaderrorMessage1, setisUploadErrorMessage1] = useState(false);
  const [totalDuration, setTotalDuration] = useState("");
  const [isFormdisabled, setisFormdisabled] = useState(false);
  const [totalSum, setTotalSum] = useState("");
  const [isValid, setValid] = useState(false);
  const [file, setFile] = useState(null);
  const [fileCount, setFileCount] = useState(0);
  const [additionalFile, setAdditionalFile] = useState(null);
  const [filebase64, setFilebase64] = useState(null);
  const [deletedResponse, setdeletedResponse] = useState(false);
  const [deletedResponseMsg, setdeletedResponseMsg] = useState("");
  const [replaceFiles, setReplaceFiles] = useState([]);
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const [isConfirmedDelete, setIsConfirmedDelete] = useState(false);
  const [deleteIndexDefendantPantiff, setDeleteIndexDefendantPantiff] =
    useState(-1);
  const [deleteMessage, setDeleteMessage] = useState("");
  const childRef = useRef();
  //// delete from fileupload controller
  const [eventFileRemove, setEventFileRemove] = useState(undefined);
  const [fileFileRemove, setFileFileRemove] = useState(undefined);
  const [fileRemoveIndex, setFileRemoveIndex] = useState(-1);
  const ref = React.createRef();
  //// delete file from Addtional file list
  const [additionalFileRemove, setAdditionalFileRemove] = useState(undefined);

  //// delete case files
  const [caseReference, setCaseReference] = useState(false);
  const [isResubmit, setIsResubmit] = useState(false);
  const [voidenvelopeId, setEnvelopeId] = useState("");
  const [textData, setTextData] = useState("");
  const [textData1, setTextData1] = useState("");
  const [isFormscheduled, setisFormscheduled] = useState(false);
  const [isHearingSentForDocusign, setisHearingSentForDocusign] =
    useState(false);
  const [isHearingsubmitted, setisHearingsubmitted] = useState(false);
  const { selectedCaseForDecisionApply } = useSelector(
    (state) => state.casereducer
  );
  const [showDecisionTemplateDialog, setShowDecisionTemplateDialog] =
    useState(false);
  const [isCasesSelectedForDecisionApply, setIsCasesSelectedForDecisionApply] =
    useState(false);
  const [selectedItems, setSelectedItems] = useState([]);
  const [allHearingByCaseCode, setAllHearingByCaseCode] = useState([]);
  const [pmaDetailsForSelectedCases, setPmaDetailsForSelectedCases] = useState(
    []
  );
  const [
    isApplyingDecisionTemplateToCases,
    setIsApplyingDecisionTemplateToCases,
  ] = useState(false);

  const massFileCases = localStorage.getItem("massFileCasesForDecisionTemplate")
    ? decryptItem("massFileCasesForDecisionTemplate") ?? []
    : [];
  useEffect(() => {
    /* Check form validation by Dev*/
    if (submitRefresh > 0) {
      setIsFormSubmit(true);
    }
  }, [submitRefresh, pmaDetails]);
  useEffect(() => {
    const fileValid = document.getElementsByClassName("file-validation");
    if (fileValid.length > 0) {
      setIsFileUpload(false);
      setValid(false);
      //setIsFormSubmit(true);
    }
  }, [isFileUpload, isValid]);
  //Grammar Checking Stuff V V ################################################################
  useEffect(() => {
    //setAdditionalNotes(textData);
    //   setisadditionaNotesError(false);
    setPMADetails({
      ...pmaDetails,
      decision: extractTextFromHTML(textData) == "" ? "" : textData,
    });
  }, [textData]);

  //############################################################################################
  useEffect(() => {
    if (isConfirmedDelete) {
      if (deleteIndexDefendantPantiff !== -1) {
        deleteParticipants(deleteIndexDefendantPantiff);
        setDeleteIndexDefendantPantiff(-1);
        setHilightDefendent(false);
        sethilightPlantiff(false);
      }
      if (fileRemoveIndex !== -1) {
        removeFile(eventFileRemove, fileFileRemove, fileRemoveIndex);
        setFileRemoveIndex(-1);
        setEventFileRemove(undefined);
        setFileFileRemove(undefined);
      }
      if (additionalFileRemove) {
        deleteAdditionalResponse(additionalFileRemove);
        if (additionalFileRemove === markAsPmaFile) {
          setMarkAsPmaFile(null);
          setMarkAsPmaUploadFile(null);
          setMarkAsPmaDecisioFile(null);
          setdecisionFileAvailable(false);
        }
        setAdditionalFileRemove(undefined);
      }
      if (caseReference) {
        deleteResponse();
        setCaseReference(false);
      }
      setIsConfirmedDelete(false);
    }
  }, [isConfirmedDelete]);
  useEffect(() => {
    console.log("CALLED CALLED", selectedCaseForDecisionApply);

    setIsFileUpload(false);

    getPMADetails();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCaseForDecisionApply]);
  useEffect(() => {
    setValid(validate(caseUnsettled));
    //// eslint-disable-next-line react-hooks/exhaustive-deps
  }, [amountError, plaintiffError, defendantError, pmaDetails]);
  useEffect(() => {
    if (fileCount) {
      const totalFIle =
        file.length +
        (additionalFile !== null && additionalFile.length !== undefined
          ? additionalFile.length
          : 0);
      if (totalFIle > 15) {
        setisUploadErrorMessage1(true);
        setUploadErrorMessage1("Maximum 15 files can be uploaded");
        setIsFileUpload(false);
      } else {
        setisUploadErrorMessage1(false);
        setUploadErrorMessage1("");
        setIsFileUpload(true);
      }
    }
  }, [fileCount]);
  //// file upload
  const setFileDropperValue = async () => {
    console.log("focused");
    let fileInput = document.getElementById("file_uploader");
    setTimeout(() => {
      fileInput.value = "";
    }, 1000);
  };

  const onkeydown = (event) => {
    if (event.keyCode === 8 || event.keyCode === 46) {
      console.log("onkeydown");
    }
  };
  const getZoomMeetingDetails = (zoomhearingCode, partiesName) => {
    const url = `${ZOOMMEETING_DETAILS}?hearingCode=${zoomhearingCode}`;
    axios
      .get(url, {
        headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
      })
      .then((res) => {
        if (res.status === 200) {
          if (res.data.length > 0) {
            let zoommeetingresult = res.data[0];
            setattendies(zoommeetingresult.zoom_attendees);
          } else {
            setattendies(partiesName);
          }
        }
      });
  };
  const TimesheetNotSubmitted =
    (props.caseDetails.isTimeSheetSubmitted !== null &&
    props.caseDetails.isTimeSheetSubmitted !== undefined
      ? !props.caseDetails.isTimeSheetSubmitted
      : true) &&
    !props.caseDetails.isCommercialCase &&
    !Utility.IsCaseDecision(props.caseDetails["caseStatus"]);
  const matchedCase = selectedCaseForDecisionApply
    ? allHearingByCaseCode.find(
        (x) =>
          String(x.caseCode) === String(selectedCaseForDecisionApply.caseCode)
      )
    : undefined;

  const isForMassFile =
    props.hearingDetails?.rowData?.massFileId && !selectedCaseForDecisionApply;

  const updatedisplayAlert = (e) => {
    setdisplayAlertError(false);
    setIsLoaded(true);
  };
  const changeAttendies = (e) => {
    setattendies(e.target.value);
  };
  const onCaseUnsettledChange = () => {
    setcaseUnsettled(!caseUnsettled);
    setBtnHandle(!caseUnsettled);
    const isValidTemp = validate(!caseUnsettled);
    setValid(isValidTemp);
    setIsFormSubmit(isValidTemp);
  };
  const dataURLtoFile = (dataurl, filename) => {
    try {
      const arr = dataurl.split(","),
        mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]);

      let n = bstr.length;
      const u8arr = new Uint8Array(n);
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }
      return new File([u8arr], filename, { type: mime });
    } catch (ex) {}
  };
  const decisionDetailshandler = (event) => {
    setPMADetails({ ...pmaDetails, decision: event.target.value });
    if (event.target.value) sethilightDecisionDetails(false);
    else sethilightDecisionDetails(true);
  };
  const isFormScheduleFunction = async (envelopeId) => {
    const url = `${HEARINGDETAILS}?hearingCode=${hearingCode}&userId=${
      Utility.IsHearingOfficer() ? decryptItem("jCode") : decryptItem("userid")
    }&roleId=${decryptItem("roleId")}`;

    try {
      const res = await axios.get(url, {
        headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
      });

      if (res.status === 200 && res.data) {
        setAllHearingByCaseCode(res.data);
        const data = res.data[0];

        setisHearingsubmitted(data?.status !== null);
        setisHearingSentForDocusign(
          data?.status && data.status.toLowerCase() !== "voided"
        );
        setcaseUnsettled(
          data?.caseUnsettled !== null && data?.caseUnsettled !== ""
        );

        if (!Utility.IsCaseDecision(props.caseDetails["caseStatus"])) {
          // if (data?.caseUnsettled) {
          const isDifferentEnvelope = envelopeId !== (data?.envelopeId || "");
          setisFormscheduled(isDifferentEnvelope);
          // } else {
          //     setisFormscheduled(false);
          // }
        } else {
          setisFormscheduled(false);
        }

        return data?.caseUnsettled || false;
      }
    } catch (error) {
      console.error("Error fetching hearing details:", error);
      // Optional: handle error state or notifications
    }

    return false; // Return false if there's no valid data
  };

  // const isFormScheduleFunction = (envelopeid) => {

  //     const url = `${HEARINGDETAILS}?hearingCode=${hearingCode}&userId=${Utility.IsHearingOfficer()
  //         ? decryptItem("jCode")
  //         : decryptItem("userid")
  //         }&roleId=${decryptItem("roleId")}`;
  //     axios
  //         .get(url, {
  //             headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
  //         })
  //         .then((res) => {
  //             if (res.status === 200 || res.data != null) {
  //                 setisHearingsubmitted(res?.data[0]?.status === null?false:true);
  //                 setisHearingSentForDocusign((res?.data[0]?.status === null || res?.data[0]?.status === "" || res?.data[0]?.status.toLowerCase() === "voided"  ? false : true));
  //                 if (!Utility.IsCaseDecision(props.caseDetails["caseStatus"])) {
  //                     if (res?.data[0]?.caseUnsettled == null || res?.data[0]?.caseUnsettled) {
  //                         if (envelopeid !== (res?.data[0]?.envelopeId == null ? "" : res?.data[0]?.envelopeId)) {
  //                             setisFormscheduled(true);
  //                             //return true;
  //                         }
  //                         else {
  //                             setisFormscheduled(false);
  //                             //return false;
  //                             // Disabled Form with Recall Button
  //                         }
  //                     }
  //                     else {
  //                         setisFormscheduled(false);
  //                         //return false;
  //                     }

  //                 }
  //                 else {
  //                     setisFormscheduled(false);
  //                     //return false;
  //                 }

  //                 // var val = (!Utility.IsCaseDecision(props.caseDetails["caseStatus"])
  //                 //  && res?.data?.caseUnsettled && envelopeId != res?.data?.envelopeId
  //                 //  )
  //                 //   return val
  //             }

  //         });

  // }
  const getPMADetails = async () => {
    const caseCodeForApi = selectedCaseForDecisionApply
      ? selectedCaseForDecisionApply.caseCode
      : props.caseDetails["caseCode"];

    const url = `${PMA_DETAILS}?hearingCode=${hearingCode}&caseCode=${caseCodeForApi}`;
    setIsPreview(false);
    SetisDocuSigned(false);
    setisFormdisabled(false);
    setdecisionFileAvailable(false);
    axios
      .get(url, {
        headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
      })
      .then(async (res) => {
        setEnvelopeId(res?.data?.envelopeId);
        setTextData(res?.data?.decision);
        setTextData1(res?.data?.decision);

        const matchedCase = selectedCaseForDecisionApply
          ? allHearingByCaseCode.find(
              (x) =>
                String(x.caseCode) ===
                String(selectedCaseForDecisionApply.caseCode)
            )
          : undefined;

        let caseunsetlledRes = await isFormScheduleFunction(
          res?.data?.envelopeId
        );
        const tempCaseUnsettled = caseunsetlledRes;
        //isFormScheduleFunction(res?.data?.envelopeId);
        //setisFormscheduled(isFormScheduleFunction(res?.data?.envelopeId));
        setcaseUnsettled(caseunsetlledRes);
        if (res?.data?.additionalDecisionDocuments?.length > 0) {
          setisFileSendForDocusign(true);

          let isDecisonFileAvailable =
            res.data.additionalDecisionDocuments.filter(
              (itemFilter) => itemFilter.isDecisionFile === true
            );
          if (isDecisonFileAvailable.length === 0) {
            setisNewFormSubmission(true);
          } else {
            setisNewFormSubmission(false);
          }

          let getPmaFile = res.data.additionalDecisionDocuments.filter(
            (itemFilter) =>
              res?.data?.decisionFileName &&
              itemFilter.fileName === res?.data?.decisionFileName
          );
          let listOfFiles = res.data.additionalDecisionDocuments.filter(
            (itemFilter) => itemFilter.fileName !== res?.data?.decisionFileName
          );
          listOfFiles = listOfFiles.filter(
            (a, i) =>
              listOfFiles.findIndex(
                (s) => a.fileName && s.fileName && a.fileName === s.fileName
              ) === i
          );

          if (getPmaFile?.length === 1) {
            listOfFiles.unshift(getPmaFile[0]);
          }
          setAdditionalFile(listOfFiles); ////list of additinalfiledata
        } else {
          setAdditionalFile(res.data.additionalDecisionDocuments); ////list of additinalfiledata
        }
        var decisionfileCheck = res.data.additionalDecisionDocuments.filter(
          (x) => {
            return x.isDecisionFile !== null && x.isDecisionFile;
          }
        );

        if (
          decisionfileCheck !== null &&
          decisionfileCheck !== undefined &&
          decisionfileCheck.length > 0
        ) {
          setMarkAsPmaFile(decisionfileCheck[0].fileName);
          setMarkAsPmaDecisioFile(decisionfileCheck[0].fileName);
          setdecisionFileAvailable(
            Utility.IsCaseClosed(
              selectedCaseForDecisionApply
                ? matchedCase?.caseStatus
                : props.caseDetails["caseStatus"]
            ) || (decisionfileCheck[0].fileName ? true : false)
          );
        } else {
          setMarkAsPmaFile(
            res.data.decisionFileName ? res.data.decisionFileName : null
          );
          setMarkAsPmaDecisioFile(
            res.data.decisionFileName ? res.data.decisionFileName : null
          );
          setdecisionFileAvailable(
            Utility.IsCaseClosed(
              selectedCaseForDecisionApply
                ? matchedCase?.caseStatus
                : props.caseDetails["caseStatus"]
            ) || res.data.decisionFileName
              ? true
              : false
          );
        }
        if (
          res.data.emailList !== null &&
          res.data.undefined !== null &&
          res.data.emailList.length > 0
        ) {
          res.data.emailList.slice(0, res.data.emailList.length - 1);
        }

        if (res?.data?.timeSheets?.length > 0) {
          let sum = 0;
          for (let tCal = 0; tCal < res?.data?.timeSheets?.length; tCal++) {
            let localVal = calculateTotalTime(res?.data?.timeSheets[tCal]);
            sum = sum + localVal;
          }
          setTotalDuration(Utility.toHoursAndMinutes(sum));
          props.timeDurationOnDecision(Utility.toHoursAndMinutes(sum));
        }
        if (
          (res.data.status === "sent" || res.data.isDocuSigned === 1) &&
          res.data.fileName !== "" &&
          res.data.fileName !== null
        ) {
          const url1 = `${GET_DOCUSIGNFILE}?casereference=${props.caseDetails["caseReference"]}&filename=${res.data.fileName}`;

          axios
            .get(url1, {
              headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`,
              },
            })
            .then((res1) => {
              const byteString = atob(res1.data); // Add your base64-encoded byte string here

              // Convert byte string to Uint8Array
              const byteArray = new Uint8Array(byteString.length);
              for (let i = 0; i < byteString.length; i++) {
                byteArray[i] = byteString.charCodeAt(i);
              }

              // Convert Uint8Array to Blob
              const blob = new Blob([byteArray], { type: "application/pdf" });
              // Create a URL for the Blob
              const url = URL.createObjectURL(blob);
              setFilebase64(url);
              setresponse(url);
              setInitialAdditionalFiles(null); //// list of additinalfiledata
              SetisDocuSigned(
                res.data.isDocuSigned === 1 &&
                  Utility.IsCaseDecision(props.caseDetails["caseStatus"])
              );
            });
        }
        if (
          res.data !== undefined &&
          res.data !== null &&
          res.data !== "" &&
          (res.data.issueName === undefined ||
            res.data.issueName === null ||
            res.data.issueName === "")
        ) {
          const issue = props.caseDetails["liabilityDamages"];

          res.data.issueName =
            issue === undefined || issue === null || issue === ""
              ? "Liability and Damages"
              : issue.charAt(0).toUpperCase() + issue.slice(1);
          if (
            res.data.issueName !== undefined &&
            res.data.issueName !== null &&
            res.data.issueName !== ""
          ) {
            res.data.issueName = res.data.issueName
              .trim()
              .split(" ")
              .map((word) => {
                if (word !== null && word !== undefined && word.trim() !== "") {
                  return word[0].toUpperCase() + word.substring(1);
                }
              })
              .join(" ");
          }
          console.log(res.data.issueName);
        }
        if (
          res.data !== undefined &&
          res.data !== null &&
          res.data !== "" &&
          (res.data.participants === undefined ||
            res.data.participants === null)
        ) {
          res.data.participants = [
            {
              defendantName: "",
              plaintiffName: "",
              amount: 0,
            },
          ];
        }

        setisFormdisabled(
          !isForMassFile &&
            Utility.IsCaseDecision(
              matchedCase
                ? matchedCase.caseStatus
                : props.caseDetails["caseStatus"]
            ) &&
            ((res?.data?.decisionFileName === null &&
              res?.data?.decisionFileName === undefined) ||
              res?.data?.decisionFileName === "")
        );
        let tempresponse = res.data;
        if (
          tempresponse &&
          tempresponse.timeSheets !== undefined &&
          tempresponse.timeSheets !== null &&
          tempresponse.timeSheets.length > 0
        ) {
          tempresponse.timeSheets = tempresponse.timeSheets
            .slice()
            .sort((a, b) => (b.hearingDate < a.hearingDate ? 1 : -1));
        }
        if (
          res.data !== undefined &&
          res.data !== null &&
          res.data !== "" &&
          res.data.meetingAttendies !== undefined &&
          res.data.meetingAttendies !== "" &&
          res.data.meetingAttendies !== null
        ) {
          setattendies(res.data.meetingAttendies);
        } else {
          getZoomMeetingDetails(props.hearingCode, tempresponse.partiesName);
        }

        tempresponse.updatedDate = new Date();
        setPMADetails(tempresponse);
        setIsLoaded(false);

        const tempTotalSum = tempresponse.participants
          .map((x) => x.amount)
          .reduce(
            (a, b) => parseFloat(a ? a : 0.0) + parseFloat(b ? b : 0.0),
            0.0
          );
        setTotalSum(parseFloat(tempTotalSum).toFixed(2));

        if (res.data.status === "Submitted") {
          setIsSubmitted(true);
          const isValidTemp = validate(caseUnsettled, tempresponse);
          setValid(isValidTemp);
          setIsFormSubmit(isValidTemp || decisionFileAvailable);
        } else {
          if (res.data.status === "sent") {
            setIsSubmitted(true);
            const isValidTemp = validate(caseUnsettled, tempresponse);
            setValid(isValidTemp);
            setIsFormSubmit(isValidTemp || decisionFileAvailable);
          } else {
            setIsSubmitted(false);
          }
        }
      });
  };

  const getPmaDetailsForMassFileCases = async (caseCode) => {
    const url = `${PMA_DETAILS}?hearingCode=${props.hearingDetails.rowData.hearingCode}&caseCode=${caseCode}`;

    try {
      const { data } = await axios.get(url, {
        headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
      });

      let tempresponse = data;
      if (
        tempresponse &&
        tempresponse.timeSheets !== undefined &&
        tempresponse.timeSheets !== null &&
        tempresponse.timeSheets.length > 0
      ) {
        tempresponse.timeSheets = tempresponse.timeSheets
          .slice()
          .sort((a, b) => (b.hearingDate < a.hearingDate ? 1 : -1));
      }

      tempresponse.updatedDate = new Date();

      return tempresponse;
    } catch (err) {
      return null;
    }
  };

  //// file upload

  const base64ofFileObject = (fileObj) => {
    let base64 = null;
    const filereader = new FileReader();
    filereader.readAsDataURL(fileObj);
    filereader.onload = function (evt) {
      base64 = evt.target.result;
      setFilebase64(base64);
    };
  };
  const removeErrorMessage = (index, both = false) => {
    const tempError = { ...plainTiffDefentError };
    if (tempError.hasOwnProperty(index)) {
      delete tempError[index];
    }
    if (both === true) {
      if (tempError.hasOwnProperty("d" + index)) {
        delete tempError["d" + index];
      }
      if (tempError.hasOwnProperty("m" + index)) {
        delete tempError["m" + index];
      }
      let newTempError = {};
      if (Object.keys(tempError).length > 0) {
        Object.keys(tempError).forEach((element) => {
          let current_index = parseInt(element.replace(/\D/g, ""));
          console.log(current_index + "<" + index);
          if (current_index > 0 && current_index > index) {
            current_index = current_index - 1;
            let newElement = "";
            if (element.includes("d")) {
              newElement = "d" + current_index;
            } else if (element.includes("m")) {
              newElement = "m" + current_index;
            } else {
              newElement = current_index;
            }
            newTempError[newElement] = newElement;
          } else {
            newTempError[element] = tempError[element];
          }
        });
      }
      //console.log("newTempError",newTempError)
      setPlainTiffDefentError(newTempError);
      //let docIndex = document.getElementById("focusInput");
      //if(docIndex){ docIndex.click();}
      setSubmitRefresh(new Date().getTime());
    } else {
      setPlainTiffDefentError(tempError);
    }

    //ref.current &&  ref.current.focus();
  };
  //// file upload
  const onDropHandler = (files, event) => {
    setFile(files);
    setFileCount(files?.length);
    if (files !== null && files !== undefined && files.length > 0) {
      var valueArr = files.map(function (item2) {
        return item2.name;
        //return fileNameCheckforDots(item2.name);
      });
      // let valueArrFindDuplicates = files.map(function (item2) {
      //     return item2.name.substring(0,item2.name.lastIndexOf("."));
      //     // return fileNameCheckforDots(item2.name);
      // });
      var DuvalueArrplicate = valueArr.some(function (item1, idx) {
        return valueArr.indexOf(item1) !== idx;
      });
      if (DuvalueArrplicate) {
        setisUploadErrorMessage(true);
        setUploadErrorMessage(
          "File(s) in red already exists and will be overwritten on upload"
        );
        //// get duplicate file names
        let duplicatelist1 = valueArr.filter((e, i, a) => a.indexOf(e) !== i);
        let tempReplaceFiles = replaceFiles;
        for (var x = 0; x < duplicatelist1.length; x++) {
          tempReplaceFiles.push(duplicatelist1[x]);
        }
        setReplaceFiles(Array.from(new Set(tempReplaceFiles)));
        //// end get duplicate file names
      }
    }
    if (
      additionalFile !== null &&
      additionalFile !== undefined &&
      additionalFile.length > 0
    ) {
      const resadditionalFile = additionalFile.filter((n) => {
        return files.some((n2) => {
          return n.fileName === getDocxAsPDF(n2.name);
        });
      });
      if (
        resadditionalFile !== null &&
        resadditionalFile !== undefined &&
        resadditionalFile.length > 0
      ) {
        setisUploadErrorMessage(true);
        setUploadErrorMessage(
          "File(s) in red already exists and will be overwritten on upload"
        );
        let tempReplaceFiles = replaceFiles;
        for (var item = 0; item < resadditionalFile.length; item++) {
          tempReplaceFiles.push(resadditionalFile[item].fileName);
        }
        setReplaceFiles(Array.from(new Set(tempReplaceFiles)));
      }
    }

    {
      // setisUploadErrorMessage(false);
      // setUploadErrorMessage("");
      // if (!isSubmitted)
      // {
      //setFile(files);
      let temp = initialAdditionalFiles ? initialAdditionalFiles : [];
      temp.push(...files);
      if (files !== null && files !== undefined && files.length > 0) {
        setIsFileUpload(true);
        //setValid(validate(caseUnsettled));
      } else {
        setIsFileUpload(false);
        // setValid(validate(caseUnsettled));
      }
      // setIsFileUpload(true);
      // setValid(true);
      // setIsFormSubmit(false);
    }
    setFileDropperValue();
  };

  //// create reference

  const onFileRemove = (fileObj) => {
    console.log("onFileRemove");
  };

  const removeFile = (e, fileObj, index) => {
    childRef.current.removeFile(e, fileObj, index);
    if (
      fileObj.name === markAsPmaFile ||
      fileObj.name === markAsPmaUploadFile ||
      fileObj.name === markAsPmaDecisioFile
    ) {
      setMarkAsPmaFile(null);
      setMarkAsPmaUploadFile(null);
      setMarkAsPmaDecisioFile(null);
    }

    let hasDuplicate = true;
    if (
      additionalFile !== null &&
      additionalFile !== undefined &&
      additionalFile.length > 0
    ) {
      const resadditionalFile = additionalFile.filter((n) => {
        return childRef.current.state.uploadedFiles.some((n2) => {
          return n.fileName === n2.name;
        });
      });
      if (
        resadditionalFile !== null &&
        resadditionalFile !== undefined &&
        resadditionalFile.length > 0
      ) {
        hasDuplicate = false;
        setisUploadErrorMessage(true);
        setUploadErrorMessage(
          "File(s) in red already exists and will be overwritten on upload"
        );
        let allFiles = file.map(function (item) {
          return item.name;
        });
        if (
          allFiles.filter((x) => x === fileObj.name).length === 1 &&
          resadditionalFile.filter((x) => x.fileName === fileObj.name)
            .length === 0
        ) {
          let removeFileFromReplaceFiles = replaceFiles;
          setReplaceFiles(
            removeFileFromReplaceFiles.filter(function (item) {
              return item !== fileObj.name;
            })
          );
        }
      } else {
        checkduplicateFiles(file, fileObj);
      }
    }
    setFileCount(file?.length);
    hasDuplicate && checkduplicateFiles(file, fileObj);

    // setFile(null);
    setFilebase64(null);
    setresponse(null);
    if (!file || file === null || file.length === 0) {
      setIsFileUpload(false);
      //setValid(false);
      setValid(validate(caseUnsettled));
    } else {
      setIsFileUpload(true);
      //setValid(true);
      setValid(validate(caseUnsettled));
    }
    setIsFormSubmit(true);
    setPMADetails(pmaDetails);
  };

  const checkduplicateFiles = (fileList, fileRemove) => {
    let valueArr = fileList.map(function (item) {
      return item.name;
    });
    let DuvalueArrplicate = valueArr.some(function (item, idx) {
      return valueArr.indexOf(item) !== idx;
    });

    if (DuvalueArrplicate) {
      if (valueArr.filter((x) => x === fileRemove.name).length === 1) {
        let removeFileFromReplaceFiles = replaceFiles;
        setReplaceFiles(
          removeFileFromReplaceFiles.filter(function (item) {
            return item !== fileRemove.name;
          })
        );
      }
      setisUploadErrorMessage(true);
      setUploadErrorMessage(
        "File(s) in red already exists and will be overwritten on upload"
      );
    } else {
      setisUploadErrorMessage(false);
      setUploadErrorMessage("");
      setReplaceFiles([]);
    }
  };

  const renderUploadedFilesItem = (fileObj, index) => {
    // let temp = []
    // temp = historyfiles;
    // temp.push({ file })
    // setHistoryFiles(temp)
    return (
      <>
        {
          <li
            className="uploadFiles"
            style={{
              borderColor:
                replaceFiles.length > 0 &&
                (replaceFiles.indexOf(getDocxAsPDF(fileObj.name)) !== -1 ||
                  replaceFiles.indexOf(fileObj.name) !== -1)
                  ? "red"
                  : "#ccc",

              border:
                replaceFiles.length > 0 &&
                (replaceFiles.indexOf(getDocxAsPDF(fileObj.name)) !== -1 ||
                  replaceFiles.indexOf(fileObj.name) !== -1)
                  ? "1px solid red"
                  : "none",
            }}
            key={index}
          >
            <div className="row">
              <div className="col-4">
                <div className="file-action">
                  <div>
                    {/* <input type="radio" className="file-action-radio" id={file.name + "_Append"} name={file.name + "_" + index} />{" "}
              <label  className="file-label" for={file.name + "_Append"}>Append</label>

              <input type="radio" className="file-action-radio" id={file.name + "_Replace"} name={file.name + "_" + index}  />{" "}

              <label  className="file-label" for={file.name + "_Replace"}>Replace</label>
               */}
                    <input
                      type="radio"
                      className="file-action-radio"
                      checked={fileObj.name === markAsPmaUploadFile}
                      id={`${fileObj.name}_MarkAsPMA`}
                      name="MarkAsPMA"
                      value={fileObj.name}
                      onChange={markPmaFileOnChange}
                    />
                    <label
                      className="file-label"
                      for={`${fileObj.name}_MarkAsPMA`}
                    >
                      Mark as Decision
                    </label>
                  </div>
                </div>
              </div>

              <div className="col-8">
                <span className="action">
                  {" "}
                  <i
                    style={{
                      color:
                        fileObj.name.indexOf(".pdf") !== -1
                          ? "#DC1D00"
                          : "blue",
                    }}
                    className={
                      fileObj.name.indexOf(".pdf") !== -1
                        ? "fa fa-file-pdf-o"
                        : "fa fa-file-word-o"
                    }
                  ></i>
                </span>
                <span className="file-name">{fileObj.name}</span>
                {/* <span className="file-name">{fileNameCheckforDots(fileObj.name)}</span> */}
                <span
                  className="action"
                  onClick={(e) => {
                    setOpenConfirmDialog(true);
                    setDeleteMessage(
                      `Are you sure you want to delete this file: ${fileObj.name}`
                      //`Are you sure you want to delete this file: ${fileNameCheckforDots(fileObj.name)}`
                    );
                    setEventFileRemove(e);
                    setFileFileRemove(fileObj);
                    setFileRemoveIndex(index);
                  }}
                >
                  <i className="fa fa-trash"></i>
                </span>
              </div>
            </div>
          </li>
        }
      </>
    );
  };
  const fileNameCheckforDots = (fileName) => {
    let tempFileChange = fileName.split(".");
    if (
      fileName.split(".").length > 2 &&
      tempFileChange[tempFileChange.length - 1] == "pdf"
    ) {
      return (
        fileName.split(".")[0] +
        "_" +
        fileName.split(".")[1].substring(0) +
        ".pdf"
      );
    }
    return fileName;
  };
  const docSubmitHandler = () => {
    const totalFIle =
      file.length +
      (additionalFile !== null && additionalFile.length !== undefined
        ? additionalFile.length
        : 0);
    if (totalFIle > 15) {
      setisUploadErrorMessage1(true);
      setUploadErrorMessage1("Maximum 15 files can be uploaded");
      return false;
    }
    sethilightDecisionDetails(false);
    setisUploadErrorMessage(false);
    setUploadErrorMessage("");

    // if (markAsPmaFile === null) {
    //   setIfnotMarkAsPMA(true);
    //   return;
    // }

    setIsLoaded(true);
    if (markAsPmaUploadFile !== "") {
      setBtnHandle(true);
    }
    setMarkAsPmaUploadFile("");
    base64ofFileObject(file[0]);
    const url = `${MERGE_DECISIONPMA_RESPONSE}`;
    const formData = new FormData();
    const distinctFiles = file.filter(
      (a, i) => file.findIndex((s) => a.name === s.name) === i
    );
    if (file.length > 0) {
      for (var x = 0; x < distinctFiles.length; x++) {
        /// the name has to be 'files' so that .NET could properly bind it
        formData.append("files", distinctFiles[x]);
      }
    }

    if (additionalFile !== null && additionalFile.length > 0) {
      let tempAdditionalFiles = additionalFile.filter(
        (o) => !distinctFiles.some((v) => v.name === o.fileName)
      );
      if (tempAdditionalFiles.length === 0) {
        formData.append("additionalFile", []);
      }

      for (
        var additionalFileIndex = 0;
        additionalFileIndex < tempAdditionalFiles.length;
        additionalFileIndex++
      ) {
        //// the name has to be 'files' so that .NET could properly bind it
        // let tempFileChange=tempAdditionalFiles[additionalFileIndex].fileName.split(".");
        // if (tempAdditionalFiles[additionalFileIndex].fileName.split(".").length>2 && tempFileChange[tempFileChange.length-1] == 'pdf') {
        //     tempAdditionalFiles[additionalFileIndex].fileName = tempAdditionalFiles[additionalFileIndex].fileName
        //     // tempAdditionalFiles[additionalFileIndex].fileName = tempAdditionalFiles[additionalFileIndex].fileName.split(".")[0] + "_" + tempAdditionalFiles[additionalFileIndex].fileName.split(".")[1].substring(0) + ".pdf";
        // }
        tempAdditionalFiles[additionalFileIndex].fileName =
          tempAdditionalFiles[additionalFileIndex].fileName;

        formData.append(
          `additionalFile[${additionalFileIndex}].fileName`,
          tempAdditionalFiles[additionalFileIndex].fileName
        );
        formData.append(
          `additionalFile[${additionalFileIndex}].fileLink`,
          tempAdditionalFiles[additionalFileIndex].fileLink
        );
        //formData.append(`additionalFile`,additionalFile[x].fileLink);
      }

      //formData.append(`additionalFile`,JSON.stringify(additionalFile));
    } else {
      formData.append("additionalFile", []);
    }
    let statusDecision =
      markAsPmaFile === undefined || markAsPmaFile === null
        ? null
        : markAsPmaFile;

    formData.append("caseCode", props.caseDetails["caseCode"]);
    formData.append("decision", statusDecision);

    formData.append("caseReference", props.caseDetails["caseReference"]);

    const config = {
      headers: {
        "content-type": "multipart/form-data",
        "Access-Control-Allow-Origin": "*",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    };
    axios.post(url, formData, config).then((response1) => {
      let additionalFileChanges = additionalFile ? additionalFile : [];
      let docxAsPMAFile = getDocxAsPDF(markAsPmaFile);
      if (
        response1.status === 200 &&
        response1.data !== null &&
        response1.data.length > 0
      ) {
        let length = response1.data.length;

        // for (
        //   var additionalFileChangesIndex = 0;
        //   additionalFileChangesIndex < length - 1;
        //   additionalFileChangesIndex++
        // ) {
        //   {
        //     additionalFileChanges.push({
        //       fileName: response1.data[additionalFileChangesIndex].fileName,
        //       fileLink: response1.data[additionalFileChangesIndex].fileLink,
        //     });
        //   }
        // }

        for (
          var additionalFileChangesIndex = 0;
          additionalFileChangesIndex < length;
          additionalFileChangesIndex++
        ) {
          const fileIndex = additionalFileChanges.findIndex(
            (n) =>
              n.fileName === response1.data[additionalFileChangesIndex].fileName

            //n.fileName === fileNameCheckforDots(response1.data[additionalFileChangesIndex].fileName)
          );
          if (fileIndex === -1) {
            additionalFileChanges.push({
              fileName: response1.data[additionalFileChangesIndex].fileName,
              //fileName: fileNameCheckforDots(response1.data[additionalFileChangesIndex].fileName),
              fileLink: response1.data[additionalFileChangesIndex].fileLink,
              isOnlineDecisionFile:
                docxAsPMAFile ===
                response1.data[additionalFileChangesIndex].fileName
                  ? true
                  : // fileNameCheckforDots( response1.data[additionalFileChangesIndex].fileName)
                    false,
              isDecisionFile:
                docxAsPMAFile ===
                response1.data[additionalFileChangesIndex].fileName
                  ? // fileNameCheckforDots( response1.data[additionalFileChangesIndex].fileName)
                    true
                  : false,
              updateDate: response1.data[additionalFileChangesIndex].updateDate,
            });
          } else {
            additionalFileChanges[fileIndex].fileLink =
              response1.data[additionalFileChangesIndex].fileLink;
            additionalFileChanges[fileIndex].isOnlineDecisionFile =
              docxAsPMAFile ===
              response1.data[additionalFileChangesIndex].fileName
                ? //fileNameCheckforDots( response1.data[additionalFileChangesIndex].fileName)
                  true
                : false;
            additionalFileChanges[fileIndex].isDecisionFile =
              additionalFileChanges[fileIndex].isOnlineDecisionFile =
                docxAsPMAFile ===
                response1.data[additionalFileChangesIndex].fileName
                  ? //  fileNameCheckforDots(  response1.data[additionalFileChangesIndex].fileName)
                    true
                  : false;
            additionalFileChanges[fileIndex].updateDate =
              response1.data[additionalFileChangesIndex].updateDate;
          }
        }

        //// remove duplicate and  decision / pma file from additional files
        let getPmaFile = additionalFileChanges.filter(
          (itemFilter) => itemFilter.fileName === docxAsPMAFile
        );
        additionalFileChanges = additionalFileChanges.filter(
          (itemFilter) => itemFilter.fileName !== docxAsPMAFile
        );
        additionalFileChanges = additionalFileChanges.filter(
          (a, i) =>
            additionalFileChanges.findIndex(
              (s) => a.fileName && s.fileName && a.fileName === s.fileName
            ) === i
        );

        if (getPmaFile?.length === 1) {
          additionalFileChanges.unshift(getPmaFile[0]);
        }
        setAdditionalFile(additionalFileChanges);
        setPMADetails({ ...pmaDetails, updatedDate: new Date() });
        const isValidTemp = validate(caseUnsettled);
        setValid(isValidTemp);
        setIsFormSubmit(isValidTemp || decisionFileAvailable);

        setdecisionFileAvailable(
          Utility.IsCaseClosed(
            selectedCaseForDecisionApply
              ? matchedCase?.caseStatus
              : props.caseDetails["caseStatus"]
          ) ||
            (markAsPmaFile !== null &&
              markAsPmaFile !== "" &&
              markAsPmaFile !== undefined)
        );
        setFile(null);
      }
      const url1 = `${ADD_PMA_DETAILS}`;
      const fileNameString = `${props.caseDetails["caseReference"]}.pdf`;
      const decisionFileNameString = docxAsPMAFile;
      setMarkAsPmaFile(docxAsPMAFile);
      const body = {
        parties: [],
        amount: 0,
        notes: "",
        additionalNotes: "",
        caseCode: parseInt(parseInt(props.caseDetails["caseCode"])),
        status: "Submitted",
        id: "",
        fileName:
          pmaDetails.decisionFileUpdate !== null &&
          pmaDetails.decisionFileUpdate !== ""
            ? fileNameString
            : "",
        decisionFileName: decisionFileNameString,
        decision: pmaDetails.decision,
        issueName: pmaDetails.issueName,
        participants: [],
        userCode: `${decryptItem("usercode")}`,
        envelopeId: "",
        emailList: [],
        hearingCode: `${props.caseDetails["hearingCode"]}`,
        partiesName: pmaDetails.partiesName,
        caseUnsettled: caseUnsettled,
        additionalDecisionDocuments: additionalFileChanges,
        meetingAttendies: attendies,
        envelopeHistories: pmaDetails.envelopeHistories,
      };
      axios
        .post(url1, body, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        })
        .then((res) => {
          if (res.status === 200) {
            setAdditionalFile(additionalFileChanges);

            //Dcision sync back functionality

            // const PMASyncbody = {
            //   caseCode: body.caseCode,
            //   hearingCode: body.hearingCode,
            //   updatedBy: `${decryptItem("userid")}`,
            //   updateDate: new Date(),
            //   decisionDate: new Date(),
            // };
            // if (isSyncBack) {
            //   PMASync(PMASyncbody);
            // }

            //End Dcision sync back functionality

            // const url2 = `${GET_FILEWITHSAS}?filename=${fileNameString}`;

            // axios
            //   .get(url2, {
            //     headers: {
            //       Authorization: `Bearer ${localStorage.getItem("token")}`,
            //     },
            //   })
            //   .then((res1) => {
            //     setFilebase64(res1.data.result);
            //     setresponse(res1.data.result)
            //     setFile(null);
            //   });

            //setIsSubmitted(true);
            setIsFileUpload(false);
            setIsFormSubmit(false);
            setIsLoaded(false);
            childRef.current.resetUploadedFiles();
            setInitialAdditionalFiles(null); //set additional file list after upload
            //setAdditionalFile(null) //set additional file list after upload
            // setMarkAsPmaFile(null); //set pma file after upload
            // setMarkAsPmaFile(null);
            setSwitchUploads(true);
            setIsFileUpload(false);
            const isValidTemp = validate(caseUnsettled);
            setValid(isValidTemp);
            setIsFormSubmit(isValidTemp || decisionFileAvailable);
            setisFileSendForDocusign(true);
            window.scrollTo(0, 0);
          }
        })
        .catch((error) => {
          setdisplayAlertError(true);

          setIsLoaded(false);
        });
    });
  };
  const getDocxAsPDF = (docxFileName) => {
    if (docxFileName === null) {
      return null;
    }
    let fileName = docxFileName;
    let fileIndex = docxFileName.lastIndexOf(".");
    let extension = docxFileName.substring(docxFileName.lastIndexOf("."));
    if (extension === ".docx" || extension === ".doc") {
      fileName = docxFileName.substring(0, fileIndex) + "_docx.pdf";
    }
    return fileName;
  };

  const markPmaFileOnChange = (event) => {
    // let fileName = event.target.value.split(".")[0] + ".pdf"
    // if (event.target.value.split(".")[1] !== 'pdf') {
    //     fileName = event.target.value.split(".")[0] + "_" + event.target.value.split(".")[1].substring(0) + ".pdf"
    // }
    setMarkAsPmaUploadFile(event.target.value);
    setMarkAsPmaFile(event.target.value);
    setMarkAsPmaDecisioFile(event.target.value);
    // setIfnotMarkAsPMA(false);
  };

  // Grammarly Changes...
  function extractTextFromHTML(htmlContent) {
    // Create a temporary DOM element to parse the HTML
    let tempElement = document.createElement("div");
    tempElement.innerHTML = htmlContent;

    // Remove all inline style attributes and suggestion spans
    tempElement
      .querySelectorAll("[style]")
      .forEach((el) => el.removeAttribute("style"));
    tempElement
      .querySelectorAll(".suggestion")
      .forEach((el) => el.replaceWith(el.textContent));

    // Replace <br> tags with newline characters
    tempElement.querySelectorAll("br").forEach((br) => br.replaceWith("\n"));

    // Replace <p> tags with newline characters for paragraph separation
    tempElement.querySelectorAll("p").forEach((p) => {
      // Get the trimmed text content and add a newline if it's not empty
      const text = p.textContent.trim();
      if (text) {
        let newTextNode = document.createTextNode(text + "\n");
        p.replaceWith(newTextNode);
      } else {
        p.replaceWith("\n"); // Treat empty paragraphs as extra line breaks
      }
    });

    // Replace &nbsp; with a regular space
    tempElement.innerHTML = tempElement.innerHTML.replace(/&nbsp;/g, " ");

    // Use textContent to extract the text, keeping spaces but removing excessive whitespace
    let extractedText = tempElement.textContent;

    // Normalize npnmultiple spaces and newlines: collapse consecutive spaces and preserve two consecutive newlines
    extractedText = extractedText.replace(/[ \t]+/g, " "); // Collapse consecutive spaces
    //extractedText = extractedText.replace(/\n{3,}/g, '\n\n'); // Replace 3 or more newlines with exactly 2

    // Ensure that there are no unnecessary spaces before or after newlines
    //extractedText = extractedText.replace(/ *\n */g, '\n');

    return extractedText.trim(); // Trim final result to avoid unnecessary leading/trailing newlines
  }
  // Grammarly Changes End
  const SaveData = (event, fileName) => {
    if (event != null && event != undefined) {
      event.preventDefault();
    }
    setIsLoaded(false);
    setValid(validate(caseUnsettled));
    const url = `${ADD_PMA_DETAILS}`;
    const email = [];
    email.push({
      name: `${props.caseDetails["hearingOfficerName"]}`,
      email: !EDIT_NEUTRAL_EMAIL
        ? `${HearingOfficerEmailPMA}`
        : isMask
        ? `${mailTimesheet}`
        : `${props.caseDetails["hearingOfficerEmail"]}`,
      isExcluded: false,
      cCEmail: "",
      cCName: "",
      role: "",
    });
    email.forEach(function (item, index) {
      item.id = `${index}`;
      //// or do whatever you want using index
    });

    const fileNameString = file !== null && file.length > 0 ? file[0].name : "";
    const body = {
      parties: pmaDetails.parties,
      amount: pmaDetails.amount !== undefined ? pmaDetails.amount : 0,
      notes: pmaNoLater[0] !== undefined ? pmaNoLater : "",
      additionalNotes:
        pmaAdditionalNotes[0] !== undefined ? pmaAdditionalNotes : "",
      caseCode: parseInt(props.caseDetails["caseCode"]),
      status:
        pmaDetails.status == "" || pmaDetails.status == null
          ? "Submitted"
          : pmaDetails.status,
      id: pmaDetails.id == "" ? "" : pmaDetails.id,
      fileName: fileNameString,
      decision:
        extractTextFromHTML(pmaDetails.decision) === ""
          ? ""
          : pmaDetails.decision,
      decisionFileName:
        fileName != null && fileName != undefined && fileName
          ? ""
          : markAsPmaFile,
      issueName: pmaDetails.issueName,
      participants: pmaDetails.participants.filter(function (obj) {
        if (parseFloat(obj.amount)) {
          return obj;
        }
      }),
      userCode: `${decryptItem("usercode")}`,
      envelopeId:
        pmaDetails.envelopeId === "" || pmaDetails.envelopeId == null
          ? ""
          : pmaDetails.envelopeId,
      emailList: email,
      hearingCode: `${props.caseDetails["hearingCode"]}`,
      partiesName: pmaDetails.partiesName,
      caseUnsettled: caseUnsettled,
      additionalDecisionDocuments: additionalFile,
      hearingofficerName: `${props.caseDetails["hearingOfficerName"]}`,
      meetingAttendies: attendies,
      envelopeHistories: pmaDetails.envelopeHistories,
      signingParties:
        pmaDetails.signingParties?.length === 0
          ? []
          : pmaDetails.signingParties,
      caseReference:
        pmaDetails.caseReference === "" || pmaDetails.caseReference === null
          ? props.caseDetails["caseReference"]
          : pmaDetails.caseReference,
      defendantName:
        pmaDetails.defendantName === "" || pmaDetails.defendantName == null
          ? ""
          : pmaDetails.defendantName,
      plaintiffName:
        pmaDetails.plaintiffName === "" || pmaDetails.plaintiffName == null
          ? ""
          : pmaDetails.plaintiffName,
      timeSheets:
        pmaDetails.timeSheets?.length === 0 ? [] : pmaDetails.timeSheets,
    };

    axios
      .post(url, body, {
        headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
      })
      .then((res) => {
        if (res.status === 200) {
          //  alert(caseUnsettled)
          const isValidTemp = validate(caseUnsettled);
          console.log("data saved", isValidTemp);

          setValid(isValidTemp);
          setIsFormSubmit(isValidTemp);
          //setIsFormSubmit(true);
        }
      })
      .catch((error) => {
        setdisplayAlertError(true);
      });
  };

  const submitHandler = (event) => {
    if (event) event.preventDefault();
    //// 1st Scenario If case is marked as Unsettled or unresolved.
    let htmltopdf = "";
    if (caseUnsettled) {
      htmltopdf = document.getElementById("decisionTemplate");
      if (htmltopdf) {
        htmltopdf = htmltopdf.innerHTML;
        openPreview(htmltopdf, caseUnsettled, false);
      }
    } else {
      //// 2nd Scenario if uploaded decision file is available.
      if (decisionFileAvailable) {
        htmltopdf = document.getElementById("timesheetdetailsDecision");
        if (htmltopdf) {
          htmltopdf = htmltopdf.innerHTML;
          if (htmltopdf === "") {
            htmltopdf = "<div></div>";
          }
          openPreview(htmltopdf, false, true);
        }
      } else {
        htmltopdf = document.getElementById("decisionTemplate").innerHTML;
        openPreview(htmltopdf, false, false);
      }
    }
  };

  const submitHandlerForMassFile = (event) => {
    if (event) event.preventDefault();
    //// 1st Scenario If case is marked as Unsettled or unresolved.
    let htmltopdf = "";
    if (caseUnsettled) {
      htmltopdf = document.getElementById("decisionTemplate");
      if (htmltopdf) {
        htmltopdf = htmltopdf.innerHTML;
        openPreview(htmltopdf, caseUnsettled, false);
      }
    } else {
      //// 2nd Scenario if uploaded decision file is available.
      if (decisionFileAvailable) {
        htmltopdf = document.getElementById("timesheetdetailsDecision");
        if (htmltopdf) {
          htmltopdf = htmltopdf.innerHTML;
          if (htmltopdf === "") {
            htmltopdf = "<div></div>";
          }
          openPreview(htmltopdf, false, true);
        }
      } else {
        htmltopdf = document.getElementById("decisionTemplate").innerHTML;
        openPreview(htmltopdf, false, false);
      }
    }
  };

  const MergeDecision = (fileLink, fileName) => {
    const url_merge = isForMassFile
      ? `${MERGE_MULTIPLE_PDF_FOR_MASS_FILE}`
      : `${MERGE_MULTIPLE_PDF}`;
    const formData = new FormData();
    const distinctFiles =
      file !== null && file !== undefined && file.length > 0
        ? file.filter((a, i) => file.findIndex((s) => a.name === s.name) === i)
        : [];
    if (file !== null && file !== undefined && file.length > 0) {
      for (var x = 0; x < distinctFiles.length; x++) {
        //// the name has to be 'files' so that .NET could properly bind it
        formData.append("files", distinctFiles[x]);
      }
    } else {
      formData.append("files", null);
    }

    const fileIndex = additionalFile.findIndex((n) => n.fileName === fileName);
    if (fileIndex === -1) {
      additionalFile.push({
        fileName: fileName,
        fileLink: fileLink,
        isOnlineDecisionFile: true,
        isDecisionFile: true,
        updateDate: new Date(),
      });
    } else {
      additionalFile[fileIndex].fileLink = fileLink;
      additionalFile[fileIndex].isOnlineDecisionFile = true;
      additionalFile[fileIndex].isDecisionFile = true;
      additionalFile[fileIndex].updateDate = new Date();
    }
    if (additionalFile !== null && additionalFile.length > 0) {
      let tempAdditionalFiles = additionalFile.filter(
        (o) => !distinctFiles.some((v) => v.name === o.fileName)
      );
      for (
        var indexadditionalFile = 0;
        indexadditionalFile < tempAdditionalFiles.length;
        indexadditionalFile++
      ) {
        //// the name has to be 'files' so that .NET could properly bind it
        formData.append(
          `additionalFile[${indexadditionalFile}].fileName`,
          tempAdditionalFiles[indexadditionalFile].fileName
        );
        formData.append(
          `additionalFile[${indexadditionalFile}].fileLink`,
          tempAdditionalFiles[indexadditionalFile].fileLink
        );

        formData.append(
          `additionalFile[${indexadditionalFile}].isOnlineDecisionFile`,
          tempAdditionalFiles[indexadditionalFile].isOnlineDecisionFile !=
            null &&
            tempAdditionalFiles[indexadditionalFile].isOnlineDecisionFile !=
              undefined
        );
        formData.append(
          `additionalFile[${indexadditionalFile}].isDecisionFile`,
          tempAdditionalFiles[indexadditionalFile].isDecisionFile !== null &&
            tempAdditionalFiles[indexadditionalFile].isDecisionFile !==
              undefined
        );
        formData.append(
          `additionalFile[${indexadditionalFile}].updateDate`,
          tempAdditionalFiles[indexadditionalFile].updateDate !== null &&
            tempAdditionalFiles[indexadditionalFile].updateDate !== undefined
        );
        //formData.append(`additionalFile`,additionalFile[x].fileLink);
      }
    } else {
      formData.append("additionalFile", []);
    }

    formData.append("caseCode", props.caseDetails["caseCode"]);
    formData.append("decision", fileName);
    formData.append("timesheetDetailsFile", "");
    formData.append("caseReference", props.caseDetails["caseReference"]);
    const config = {
      headers: {
        "content-type": "multipart/form-data",
        "Access-Control-Allow-Origin": "*",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    };
    axios
      .post(url_merge, formData, config)
      .then((response1) => {
        if (
          response1.status === 200 &&
          response1.data !== null &&
          response1.data.length > 0
        ) {
          const link = response1.data[0].fileLink;
          const byteString = atob(response1.data[0].fileLink); // Add your base64-encoded byte string here

          // Convert byte string to Uint8Array
          const byteArray = new Uint8Array(byteString.length);
          for (let i = 0; i < byteString.length; i++) {
            byteArray[i] = byteString.charCodeAt(i);
          }

          // Convert Uint8Array to Blob
          const blob = new Blob([byteArray], { type: "application/pdf" });

          // Create a URL for the Blob
          const url = URL.createObjectURL(blob);
          setFilebase64(url);
          setresponse(url);
          setPreviewResponse("");
          setIsPreview(true);
          setIsPreviewResponse(false);

          setMarkAsPmaFile(fileName);
          setMarkAsPmaDecisioFile(fileName);
          setAdditionalFile(additionalFile);
          //setdecisionFileAvailable(true);
        }
      })
      .catch((error) => {
        setdisplayAlertError(true);
        setIsLoaded(false);
      });
  };
  const MergeDecisionTimesheet = (fileLink, fileName, timesheetDetails) => {
    const htmltopdfurl = `${HTML_TO_PDF}`;
    const signature = document.getElementById("signatureDecision").innerHTML;
    const body1 = {
      filename: `${hearingCode}PMAFile`,
      htmlInput: signature,
      casename: `${props.caseDetails["caseName"]}`,
    };
    const fileNameSign = `${props.caseDetails["caseReference"]}_signature.pdf`;
    axios
      .post(htmltopdfurl, body1, {
        headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
      })
      .then((res) => {
        if (res.status === 200) {
          const fileObj = dataURLtoFile(
            `data:application/pdf;base64,${res.data}`,
            fileNameSign
          );
          const url = INSERT_DECISION_FILE;
          const formData = new FormData();
          formData.append("file", fileObj);
          formData.append("caseReference", props.caseDetails["caseReference"]);
          formData.append("fileName", fileNameSign);
          const config = {
            headers: {
              "content-type": "multipart/form-data",
              "Access-Control-Allow-Origin": "*",
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          };
          axios
            .post(url, formData, config)
            .then((response2) => {
              if (response2.status === 200) {
                const url1 = `${GET_DECISION_FILEWITHSAS}?caseReference=${props.caseDetails["caseReference"]}&filename=${fileNameSign}`;
                axios
                  .get(url1, {
                    headers: {
                      Authorization: `Bearer ${localStorage.getItem("token")}`,
                    },
                  })
                  .then((res1) => {
                    //// Call Preview
                    if (res1.status === 200) {
                      const isForMassFile =
                        props.hearingDetails?.rowData?.massFileId &&
                        !selectedCaseForDecisionApply;
                      const url_merge = isForMassFile
                        ? `${MERGE_MULTIPLE_PDF_FOR_MASS_FILE}`
                        : `${MERGE_MULTIPLE_PDF}`;
                      const formData1 = new FormData();
                      const distinctFiles =
                        file !== null && file !== undefined && file.length > 0
                          ? file.filter(
                              (a, i) =>
                                file.findIndex((s) => a.name === s.name) === i
                            )
                          : [];
                      if (
                        file !== null &&
                        file !== undefined &&
                        file.length > 0
                      ) {
                        for (var x = 0; x < distinctFiles.length; x++) {
                          //// the name has to be 'files' so that .NET could properly bind it
                          formData1.append("files", distinctFiles[x]);
                        }
                      } else {
                        formData1.append("files", null);
                      }
                      const fileIndex = additionalFile.findIndex(
                        (n) => n.fileName === fileName
                      );
                      if (fileIndex === -1) {
                        additionalFile.push({
                          fileName: fileName,
                          fileLink: fileLink,
                          isOnlineDecisionFile: true,
                          isDecisionFile: false,
                        });
                      } else {
                        additionalFile[fileIndex].fileLink = fileLink;
                        additionalFile[fileIndex].isOnlineDecisionFile = true;
                        additionalFile[fileIndex].isDecisionFile = false;
                      }
                      const fileSignIndex = additionalFile.findIndex(
                        (n) => n.fileName === fileNameSign
                      );
                      if (fileSignIndex === -1) {
                        additionalFile.push({
                          fileName: fileNameSign,
                          fileLink: res1.data.result,
                          isOnlineDecisionFile: true,
                          isDecisionFile: false,
                        });
                      } else {
                        additionalFile[fileSignIndex].fileLink = fileLink;
                        additionalFile[
                          fileSignIndex
                        ].isOnlineDecisionFile = true;
                        additionalFile[fileIndex].isDecisionFile = false;
                      }

                      if (
                        additionalFile !== null &&
                        additionalFile.length > 0
                      ) {
                        let tempAdditionalFiles = additionalFile.filter(
                          (o) =>
                            !distinctFiles.some((v) => v.name === o.fileName)
                        );
                        for (
                          var indexadditionalFile = 0;
                          indexadditionalFile < tempAdditionalFiles.length;
                          indexadditionalFile++
                        ) {
                          //// the name has to be 'files' so that .NET could properly bind it
                          formData1.append(
                            `additionalFile[${indexadditionalFile}].fileName`,
                            tempAdditionalFiles[indexadditionalFile].fileName
                          );
                          formData1.append(
                            `additionalFile[${indexadditionalFile}].fileLink`,
                            tempAdditionalFiles[indexadditionalFile].fileLink
                          );
                          formData1.append(
                            `additionalFile[${indexadditionalFile}].isOnlineDecisionFile`,
                            tempAdditionalFiles[indexadditionalFile]
                              .isOnlineDecisionFile !== null &&
                              tempAdditionalFiles[indexadditionalFile]
                                .isOnlineDecisionFile !== undefined
                          );

                          formData1.append(
                            `additionalFile[${indexadditionalFile}].isDecisionFile`,
                            tempAdditionalFiles[indexadditionalFile]
                              .isDecisionFile !== null &&
                              tempAdditionalFiles[indexadditionalFile]
                                .isDecisionFile !== undefined
                          );
                          //formData.append(`additionalFile`,additionalFile[x].fileLink);
                        }
                      } else {
                        formData1.append("additionalFile", []);
                      }

                      formData1.append(
                        "caseCode",
                        props.caseDetails["caseCode"]
                      );
                      formData1.append(
                        "decision",
                        timesheetDetails ? markAsPmaFile : fileName
                      );
                      formData1.append(
                        "timesheetDetailsFile",
                        timesheetDetails ? fileName : ""
                      );
                      formData1.append(
                        "caseReference",
                        props.caseDetails["caseReference"]
                      );
                      const config1 = {
                        headers: {
                          "content-type": "multipart/form-data",
                          "Access-Control-Allow-Origin": "*",
                          Authorization: `Bearer ${localStorage.getItem(
                            "token"
                          )}`,
                        },
                      };
                      axios
                        .post(url_merge, formData1, config1)
                        .then((response1) => {
                          if (
                            response1.status === 200 &&
                            response1.data !== null &&
                            response1.data.length > 0
                          ) {
                            const link = response1.data[0].fileLink;
                            const byteString = atob(response1.data[0].fileLink); // Add your base64-encoded byte string here

                            // Convert byte string to Uint8Array
                            const byteArray = new Uint8Array(byteString.length);
                            for (let i = 0; i < byteString.length; i++) {
                              byteArray[i] = byteString.charCodeAt(i);
                            }

                            // Convert Uint8Array to Blob
                            const blob = new Blob([byteArray], {
                              type: "application/pdf",
                            });

                            // Create a URL for the Blob
                            const _url = URL.createObjectURL(blob);

                            setPreviewResponse("");
                            setIsPreviewResponse(false);
                            setFilebase64(_url);
                            setresponse(_url);
                            setIsPreview(true);

                            setMarkAsPmaFile(response1.data[0].fileName);
                            setMarkAsPmaDecisioFile(response1.data[0].fileName);
                            let additionalFileUpdate = additionalFile.filter(
                              function (obj) {
                                return obj.fileName !== fileNameSign;
                              }
                            );
                            additionalFileUpdate = additionalFileUpdate.filter(
                              function (obj) {
                                return obj.fileName !== fileName;
                              }
                            );
                            setAdditionalFile(additionalFileUpdate);
                            //     setdecisionFileAvailable(true);
                          }
                        })
                        .catch((error) => {
                          setdisplayAlertError(true);
                          setIsLoaded(false);
                        });
                    }
                  })
                  .catch((error) => {
                    setdisplayAlertError(true);
                    setIsLoaded(false);
                  });
              }
            })
            .catch((error) => {
              setdisplayAlertError(true);
              setIsLoaded(false);
            });
        }
      })
      .catch((error) => {
        setdisplayAlertError(true);
        setIsLoaded(false);
      });
  };

  //// New Code for Preview
  //// htmltopdf html for which pdf needs to be generated
  //// caseResolved whether case is unsettled/ unresolved
  //// timesheetDetails Whether the pdf is only for timesheet details
  const openPreview = (htmltopdf, caseResolved, timesheetDetails) => {
    setIsLoaded(true);

    const htmltopdfurl = `${HTML_TO_PDF}`;

    const body1 = {
      filename: `${hearingCode}PMAFile`,
      htmlInput: htmltopdf,
      casename: `${props.caseDetails["caseName"]}`,
    };
    const fileName = `${props.caseDetails["caseReference"]}${
      timesheetDetails ? "_timesheet" : "_decision"
    }.pdf`;
    axios
      .post(htmltopdfurl, body1, {
        headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
      })
      .then((res) => {
        if (res.status === 200) {
          const fileObj = dataURLtoFile(
            `data:application/pdf;base64,${res.data}`,
            fileName
          );
          const url = INSERT_DECISION_FILE;
          const formData = new FormData();
          formData.append("file", fileObj);
          formData.append("caseReference", props.caseDetails["caseReference"]);
          formData.append("fileName", fileName);
          const config = {
            headers: {
              "content-type": "multipart/form-data",
              "Access-Control-Allow-Origin": "*",
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          };
          axios
            .post(url, formData, config)
            .then((response2) => {
              if (response2.status === 200) {
                const url1 = `${GET_DECISION_FILEWITHSAS}?caseReference=${props.caseDetails["caseReference"]}&filename=${fileName}`;
                axios
                  .get(url1, {
                    headers: {
                      Authorization: `Bearer ${localStorage.getItem("token")}`,
                    },
                  })
                  .then((res1) => {
                    //// Call Preview
                    if (res1.status === 200) {
                      const byteString = atob(res1.data); // Add your base64-encoded byte string here

                      // Convert byte string to Uint8Array
                      const byteArray = new Uint8Array(byteString.length);
                      for (let i = 0; i < byteString.length; i++) {
                        byteArray[i] = byteString.charCodeAt(i);
                      }

                      // Convert Uint8Array to Blob
                      const blob = new Blob([byteArray], {
                        type: "application/pdf",
                      });
                      // Create a URL for the Blob
                      const url = URL.createObjectURL(blob);
                      if (caseResolved) {
                        setFilebase64(url);
                        setresponse(url);
                        setIsPreview(true);
                        setPreviewResponse("");
                        setIsPreviewResponse(false);
                      } else if (timesheetDetails) {
                        MergeDecisionTimesheet(url, fileName, timesheetDetails);
                      } else {
                        MergeDecision(url, fileName);
                      }
                    } else {
                      setFilebase64(null);
                      setresponse(null);
                      setIsLoaded(false);
                    }
                  });
              }
            })
            .catch((error) => {
              setdisplayAlertError(true);
              setIsLoaded(false);
            });
        }
      })
      .catch((error) => {
        setdisplayAlertError(true);
        setIsLoaded(false);
      });
  };

  const PMASync = (data) => {
    const PMASyncbody = {
      caseCode: data.caseCode,
      hearingCode: data.hearingCode,
      caseStatus: "HEARD",
      updatedBy: `${decryptItem("usercode")}`,
      updateDate: new Date(),
      decisionDate: new Date(),
    };
    const config = {
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    };
    const hearingDetails = JSON.parse(decryptItem("hearingDetails"));
    hearingDetails.pendingAt = "decision";
    encryptItem("hearingDetails", JSON.stringify(hearingDetails));
    window.location.href = props.decisionUrl;
    window.location.reload();
  };

  const cancelPreview = () => {
    setIsPreview(false);
    //setFilebase64(null);
    //setresponse(null);
    setIsLoaded(false);
    var fileName1 = `${props.caseDetails["caseReference"]}` + `_decision.pdf`;
    if ((response || additionalFile.length > 0) && isFileSendForDocusign) {
      setisShowDeleteMessage(true);
      setisFileSendForDocusign(true);
    } else {
      setisShowDeleteMessage(false);
      //  // deleteResponse(false);
      deleteAdditionalResponse(fileName1, false);
      if (additionalFile.length > 0) {
        for (let i = 0; i < additionalFile.length; i++) {
          deleteAdditionalResponse(additionalFile[i].fileName, false);
        }
      }
      setisFileSendForDocusign(false);
    }
    if (isNewFormSubmission) {
      deleteOnCancelPreview(fileName1, false);
    }
  };
  const handleAmtPayError = (event, index) => {
    const { value } = event;
    if (value.trim() === "") {
      const tempError = { ...plainTiffDefentError };
      tempError[index] = "Required";
      setPlainTiffDefentError(tempError);
    } else {
      removeErrorMessage(index);
    }
  };

  const sendForDocusign = (event) => {
    event.preventDefault();
    setisNewFormSubmission(false);
    setIsLoaded(false);
    const url = `${ADD_PMA_DETAILS}`;
    const email = [];

    email.push({
      name: `${props.caseDetails["hearingOfficerName"]}`,
      email: !EDIT_NEUTRAL_EMAIL
        ? `${HearingOfficerEmailPMA}`
        : isMask
        ? `${mailTimesheet}`
        : `${props.caseDetails["hearingOfficerEmail"]}`,
      isExcluded: false,
      cCEmail: "",
      cCName: "",
      role: "",
    });
    email.forEach(function (item, index) {
      item.id = `${index}`;
      //// or do whatever you want using index
    });

    let caseCodeForDecision = parseInt(props.caseDetails["caseCode"]);

    if (
      props.hearingDetails?.rowData?.massFileId &&
      selectedCaseForDecisionApply
    ) {
      caseCodeForDecision = parseInt(selectedCaseForDecisionApply.caseCode);
    }

    const fileNameString = markAsPmaDecisioFile;
    const body = pmaDetails;
    body.parties = pmaDetails.parties;
    body.isDocuSigned = 0;
    body.amount = pmaDetails.amount !== undefined ? pmaDetails.amount : 0;
    body.notes = pmaNoLater[0] !== undefined ? pmaNoLater : "";
    body.additionalNotes =
      pmaAdditionalNotes[0] !== undefined ? pmaAdditionalNotes : "";
    body.caseCode = caseCodeForDecision;
    body.status = "sent";
    body.id = "";
    body.fileName = fileNameString;
    body.decisionFileName = markAsPmaFile;
    body.decision = pmaDetails.decision;
    body.issueName = pmaDetails.issueName;
    body.participants = pmaDetails.participants;
    body.userCode = `${decryptItem("usercode")}`;
    body.envelopeId = "";
    body.emailList = email;
    body.hearingCode = `${props.caseDetails["hearingCode"]}`;
    body.partiesName = pmaDetails.partiesName;
    body.caseUnsettled = caseUnsettled;
    body.additionalDecisionDocuments = additionalFile;
    body.hearingofficerName = `${props.caseDetails["hearingOfficerName"]}`;
    body.meetingAttendies = attendies;
    body.envelopeHistories = pmaDetails.envelopeHistories;
    axios
      .post(url, body, {
        headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
      })
      .then((res) => {
        if (res.status === 200) {
          setIsFileUpload(false);
          //pushdocument(htmltopdf, email, body);
          const data = body;
          data.fileName = markAsPmaFile;
          data.env = env;
          data.caseMethod = "Arbitration";
          data.caseReference = props.caseDetails["caseReference"];
          data.caseName = `${props.caseDetails["caseName"]}`;
          data.status = "sent";
          axios
            .post(DOCUSIGN_CONNECTOR, data)
            .then((response3) => {
              if (response3.status === 200) {
                setisFileSendForDocusign(true);
                if (isSyncBack) {
                  PMASync(data);
                } else {
                  window.location.href = props.decisionUrl;
                  window.location.reload();
                }
              }
            })
            .catch((error) => {
              setdisplayAlertError(true);
              setIsLoaded(false);
            });
          setIsSubmitted(true);
          setIsFormSubmit(true);
          setIsLoaded(false);
          setIsPreview(false);
          setFilebase64(null);
          setresponse(null);
          setIsApplyingDecisionTemplateToCases(false);
          props.callBackreloadcasedetails();

          if (Utility.IsHearingOfficer()) {
            get(
              `${GET_PENDINGCOUNTFORNEUTRAL}?neutralEmail=${decryptItem(
                "neutralsEmail"
              )}&userCode=${decryptItem("jCode")}`,
              {
                headers: {
                  Authorization: `Bearer ${localStorage.getItem("token")}`,
                },
              }
            ).then((resp2) => {
              localStorage.setItem("pendingTaskCount", `${resp2.data}`);
            });
          }
        }
      })
      .catch((error) => {
        setdisplayAlertError(true);
      });
  };

  console.log(props.hearingDetails);

  const sendForDocSignForMassFile = (event) => {
    event.preventDefault();
    console.log("CALLED");
    setisNewFormSubmission(false);
    setIsLoaded(false);
    const url = `${ADD_PMA_DETAILS_FOR_MASS_FILE}`;
    const email = [];

    email.push({
      name: `${props.hearingDetails.rowData["hearingOfficerName"]}`,
      email: !EDIT_NEUTRAL_EMAIL
        ? `${HearingOfficerEmailPMA}`
        : isMask
        ? `${mailTimesheet}`
        : `${props.hearingDetails.rowData["hearingOfficerEmail"]}`,
      isExcluded: false,
      cCEmail: "",
      cCName: "",
      role: "",
    });
    email.forEach(function (item, index) {
      item.id = `${index}`;
      //// or do whatever you want using index
    });

    const requestPayload = [];

    pmaDetailsForSelectedCases.forEach((item) => {
      const fileNameString = markAsPmaDecisioFile;
      const body = item;

      body.parties = pmaDetails.parties;
      body.isDocuSigned = 0;
      body.amount = pmaDetails.amount !== undefined ? pmaDetails.amount : 0;
      body.notes = pmaNoLater[0] !== undefined ? pmaNoLater : "";
      body.additionalNotes =
        pmaAdditionalNotes[0] !== undefined ? pmaAdditionalNotes : "";
      body.caseCode = parseInt(item.caseCode);
      body.status = "sent";
      body.id = "";
      body.fileName = fileNameString;
      body.decisionFileName = markAsPmaFile;
      body.decision = pmaDetails.decision;
      body.issueName = pmaDetails.issueName;
      body.participants = pmaDetails.participants;
      body.userCode = `${decryptItem("usercode")}`;
      body.envelopeId = "";
      body.emailList = email;
      body.hearingCode = `${props.hearingDetails.rowData["hearingCode"]}`;
      body.partiesName = pmaDetails.partiesName;
      body.caseUnsettled = caseUnsettled;
      body.additionalDecisionDocuments = additionalFile;
      body.hearingofficerName = `${props.hearingDetails.rowData["hearingOfficerName"]}`;
      body.meetingAttendies = attendies;
      body.envelopeHistories = pmaDetails.envelopeHistories;

      requestPayload.push({ ...body });
    });

    axios
      .post(url, requestPayload, {
        headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
      })
      .then((res) => {
        if (res.status === 200) {
          sendDocumentsForSignAfterSubmittingPma(requestPayload);
          setIsFileUpload(false);

          setIsSubmitted(true);
          setIsFormSubmit(true);
          setIsLoaded(false);
          setIsPreview(false);
          setFilebase64(null);
          setresponse(null);
          setIsApplyingDecisionTemplateToCases(false);
          props.callBackreloadcasedetails();
        }
      })
      .catch((error) => {
        setdisplayAlertError(true);
      });
  };

  const sendDocumentsForSignAfterSubmittingPma = async (requests) => {
    requests.forEach((item) => {
      item.fileName = item.decisionFileName;
      item.env = env;
      item.caseMethod = "Arbitration";
      item.caseName = `Sg test case`; // TODO
      item.status = "sent";
    });
    const mappedRequests = requests.map((data) =>
      axios.post(DOCUSIGN_CONNECTOR, data)
    );

    const test = await Promise.all(mappedRequests);
    console.log(test);

    setIsLoaded(false);
  };

  const getTotalTime = (timesheets) => {
    const totalspentminutes = calculateTotalTime(timesheets);
    return Utility.toHoursAndMinutes(totalspentminutes);
  };
  const calculateTotalTime = (timesheets) => {
    if (timesheets !== undefined && timesheets !== null) {
      const totalpostMedTimehours = timesheets.postMedTimehours;
      const totalrevDocTimehours = timesheets.revDocTimehours;
      const totalpostMedTimeminutes = timesheets.postMedTimeminutes;
      const totalrevDocTimeminutes = timesheets.revDocTimeminutes;
      const totalhearingDuration = timesheets.hearingDuration;
      let totalspentminutes =
        totalpostMedTimeminutes +
        totalrevDocTimeminutes +
        totalhearingDuration +
        totalpostMedTimehours * 60 +
        totalrevDocTimehours * 60;

      const arbitrationTime =
        totalhearingDuration +
        totalrevDocTimeminutes +
        totalrevDocTimehours * 60;
      totalspentminutes =
        arbitrationTime < timesheets.hearingScheduledDuration * 60
          ? timesheets.hearingScheduledDuration * 60
          : arbitrationTime;

      return totalspentminutes;
    }
    return 0;
  };
  const onIssueNameChangeHandler = (event) => {
    setPMADetails({ ...pmaDetails, issueName: event.target.value });
  };
  const deleteParticipants = (index) => {
    pmaDetails.participants.splice(index, 1);
    setPMADetails({ ...pmaDetails, participants: pmaDetails.participants });
    setMessage("");
    setIsAmountError(false);
    setIsPaintiffError(false);
    setIsdefendantNameError(false);
    setErrorMessage("");
    const tempTotalSum = pmaDetails.participants
      .map((x) => x.amount)
      .reduce((a, b) => parseFloat(a ? a : 0.0) + parseFloat(b ? b : 0.0), 0.0);
    setTotalSum(parseFloat(tempTotalSum).toFixed(2));
  };

  const handleAddContainer = () => {
    if (!amountError && !plaintiffError && !defendantError) {
      const obj = {
        defendantName: "",
        plaintiffName: "",
        amount: "0",
      };

      if (
        pmaDetails.participants === undefined ||
        pmaDetails.participants === null
      ) {
        pmaDetails.participants = [];
      }

      const list = pmaDetails.participants;
      const tempError = { ...plainTiffDefentError };
      tempError[list.length] = list.length;
      tempError["d" + list.length] = list.length;
      setPlainTiffDefentError(tempError);
      list.push(obj);
      setPMADetails({ ...pmaDetails, participants: list });
    } else {
      setMessage("Please resolve errors first");
    }
  };

  const onPlaintiffNameChange = (event, index) => {
    const participant = pmaDetails.participants;
    const obj = participant[index];
    const value = event.target.value;
    obj.plaintiffName = event.target.value;
    participant[index] = obj;
    if (
      obj.errorMessage === "" ||
      obj.errorMessage === undefined ||
      obj.errorMessage === null
    ) {
      obj.errorMessage = "";
    }
    if (value === "") {
      if (!obj.errorMessage.includes("Plaintiff name required")) {
        setPMADetails({ ...pmaDetails, participants: participant });
        if (
          obj.errorMessage === "" ||
          obj.errorMessage === undefined ||
          obj.errorMessage === null
        ) {
          obj.errorMessage = `${obj.errorMessage.replace(
            ",",
            ""
          )}  Plaintiff name required`;
          setErrorMessage(
            `${errorMessage.replace(",", "")}  Plaintiff name required`
          );
        } else {
          obj.errorMessage = `${obj.errorMessage.replace(",", "")} ${
            obj.errorMessage.replace(",", "").trim() ? "," : ""
          }  Plaintiff name required`;
          setErrorMessage(
            `${errorMessage.replace(",", "")} ${
              errorMessage.replace(",", "").trim() ? "," : ""
            }  Plaintiff name required`
          );
        }
        obj.hilightPlantiff = true;
        sethilightPlantiff(true);
      }
      //setIsPaintiffError(true);
      const tempError = { ...plainTiffDefentError };
      tempError[index] = index;
      setPlainTiffDefentError(tempError);
      setMessage("");
      setIsPaintiffError(true);
    } else {
      obj.errorMessage = obj.errorMessage
        .replace("Plaintiff name required", "")
        .replace(",", "");
      setErrorMessage(
        errorMessage.replace("Plaintiff name required", "").replace(",", "")
      );
      setIsPaintiffError(false);
      obj.hilightPlantiff = false;
      sethilightPlantiff(false);
      removeErrorMessage(index);
    }

    setPMADetails({ ...pmaDetails, participants: participant });
    setCurrentIndex(index);
  };

  const onDefendantChange = (event, index) => {
    const participant = pmaDetails.participants;
    const obj = participant[index];
    const value = event.target.value;
    obj.defendantName = value;
    participant[index] = obj;
    if (
      obj.errorMessage === "" ||
      obj.errorMessage === undefined ||
      obj.errorMessage === null
    ) {
      obj.errorMessage = "";
    }
    if (value !== "") {
      setMessage("");
      obj.errorMessage = obj.errorMessage
        .replace("Defendant name required", "")
        .replace(",", "");
      setErrorMessage(
        errorMessage.replace("Defendant name required", "").replace(",", "")
      );
      setIsdefendantNameError(false);
      obj.HilightDefendent = false;
      setHilightDefendent(false);
      removeErrorMessage("d" + index);
    } else {
      if (!obj.errorMessage.includes("Defendant name required")) {
        setPMADetails({ ...pmaDetails, participants: participant });
        if (
          obj.errorMessage === "" ||
          obj.errorMessage === undefined ||
          obj.errorMessage === null
        ) {
          obj.errorMessage = `${obj.errorMessage.replace(
            ",",
            ""
          )}  Defendant name required`;
          setErrorMessage(
            `${errorMessage.replace(",", "")}  Defendant name required`
          );
        } else {
          obj.errorMessage = `${obj.errorMessage.replace(",", "")} ${
            obj.errorMessage.replace(",", "").trim() ? "," : ""
          }  Defendant name required`;
          setErrorMessage(
            `${errorMessage.replace(",", "")} ${
              errorMessage.replace(",", "").trim() ? "," : ""
            }  Defendant name required`
          );
        }
        setIsdefendantNameError(true);
        obj.HilightDefendent = true;
        setHilightDefendent(true);
      }
      const tempError = { ...plainTiffDefentError };
      tempError["d" + index] = index;
      setPlainTiffDefentError(tempError);
    }

    setPMADetails({ ...pmaDetails, participants: participant });
    setCurrentIndex(index);
  };

  const onValueChange = (event, index) => {
    const { value } = event;

    const participant = pmaDetails.participants;
    const obj = participant[index];
    const re = /^\s*[1-9]\d*(\.\d)?\s*$/;
    let valueFixed = 0;
    if (value !== "" && value !== null && value !== undefined) {
      valueFixed = parseFloat(value).toFixed(2);
    }
    obj.amount =
      value === "" || value === null || value === undefined
        ? value
        : valueFixed;
    participant[index] = obj;
    /*if (value === "") {
            if (obj && !obj.errorMessage.includes("Invalid Amount")) {
                setErrorMessage(`${errorMessage}  Invalid Amount`);
            }
            setIsAmountError(true);
        }*/
    if (value === "" || re.test(value)) {
      /* let amountSum = 0;

            participant.map((i) => {
                if (i.amount === "") {
                    amountSum = 0;
                } else {
                    amountSum = value;
                }
            });
            setPMADetails({
                ...pmaDetails,
                participants: participant,
                amount: amountSum,
            });
            setMessage("");
            obj.errorMessage.replace("Invalid Amount", "");
            setErrorMessage(errorMessage.replace("Invalid Amount", ""));
            setIsAmountError(false);*/
    } else {
      //   if(!errorMessage.includes("Invalid Amount"))
      //   {
      //     setErrorMessage(`${errorMessage}  Invalid Amount`);
      // }
      //   setIsAmountError(true);
      setPMADetails({
        ...pmaDetails,
        participants: participant,
      });
    }
    const tempTotalSum = pmaDetails.participants
      .map((x) => x.amount)
      .reduce((a, b) => parseFloat(a ? a : 0.0) + parseFloat(b ? b : 0.0), 0.0);
    setTotalSum(parseFloat(tempTotalSum).toFixed(2));
  };

  const validate = (caseUnsettledCheck = false, tempresponse) => {
    let val = true;
    const details =
      tempresponse === undefined || tempresponse === null
        ? pmaDetails
        : tempresponse;

    if (caseUnsettledCheck) {
      //setIsFormSubmit(true);
      return val;
    } else if (
      !amountError &&
      !plaintiffError &&
      !defendantError &&
      details.decision &&
      details.decision.length > 0 &&
      details.issueName &&
      details.issueName.trim().length > 0
    ) {
      if (details.participants !== undefined && details.participants !== null) {
        details.participants.forEach((y) => {
          if (
            y.plaintiffName.trim().length === 0 ||
            y.defendantName.trim().length === 0
          ) {
            val = false;
          }
        });
      }
      return val;
    } else {
      return false;
    }
  };

  const handleswitchNewUploads = () => {
    setSwitchUploads(false);
    setReplaceFiles([]);
    setFile(null);
  };
  const handleSwitchHistory = () => {
    setSwitchUploads(true);
    //setValid(false);
    setIsFileUpload(false);
    // setMarkAsPmaFile(null);
    setUploadErrorMessage("");
    setReplaceFiles([]);
  };

  const viewResponse = (fileName) => {
    const url1 = `${GET_DECISION_FILEWITHSAS}?caseReference=${props.caseDetails["caseReference"]}&filename=${fileName}`;
    axios
      .get(url1, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then((res1) => {
        const byteString = atob(res1.data); // Add your base64-encoded byte string here

        // Convert byte string to Uint8Array
        const byteArray = new Uint8Array(byteString.length);
        for (let i = 0; i < byteString.length; i++) {
          byteArray[i] = byteString.charCodeAt(i);
        }

        // Convert Uint8Array to Blob
        const blob = new Blob([byteArray], { type: "application/pdf" });
        // Create a URL for the Blob
        const url = URL.createObjectURL(blob);
        setPreviewResponse(url);
        setIsPreviewResponse(true);
        setIsPreview(true);
      });
  };

  const deleteResponse = (showmessgae) => {
    setIsLoaded(true);
    if (showmessgae === undefined || showmessgae === null) {
      showmessgae = true;
    }
    const url = `${DELETE_PMA_RESPONSE}?caseCode=${parseInt(
      props.caseDetails["caseCode"]
    )}`;
    const body = {
      caseCode: parseInt(props.caseDetails["caseCode"]),
    };
    axios
      .post(url, body, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then((res1) => {
        if (res1.status === 200) {
          const url1 = `${DELETE_RESPONSE}/${props.caseDetails["caseReference"]}.pdf`;
          axios
            .get(url1, {
              headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`,
              },
            })
            .then((res3) => {
              if (res3.status === 200) {
                setresponse(null);
                setdecisionFileAvailable(false);
                if (isShowDeleteMessage && showmessgae) {
                  setdeletedResponse(true);
                  setdeletedResponseMsg(
                    "Decision has been deleted successfully"
                  );
                }
              }
            });
        }
        setIsLoaded(false);
      });
  };

  // const downloadResponse = async (fileLink, fileName) => {
  //   const linkSource = `${fileLink.substring(0, fileLink.indexOf("?"))}`;
  //   const downloadLink = document.createElement("a");

  //   downloadLink.href = linkSource;
  //   downloadLink.download = fileName;
  //   downloadLink.click();
  // };

  const downloadResponse = async (fileName) => {
    const url1 = `${GET_DECISION_FILEWITHSAS}?caseReference=${props.caseDetails["caseReference"]}&filename=${fileName}`;
    axios
      .get(url1, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then((res1) => {
        // fetch(res1.data.result, {
        //     method: "GET",
        //     headers: {
        //         "Content-Type": "application/pdf",
        //     },
        // })
        //     .then((response) => {
        const byteString = atob(res1.data); // Add your base64-encoded byte string here

        // Convert byte string to Uint8Array
        const byteArray = new Uint8Array(byteString.length);
        for (let i = 0; i < byteString.length; i++) {
          byteArray[i] = byteString.charCodeAt(i);
        }

        // Convert Uint8Array to Blob
        const blob = new Blob([byteArray], { type: "application/pdf" });
        // Create a URL for the Blob
        const url = URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;
        link.download = fileName;
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
        // });
      });
  };

  const deleteAdditionalResponse = async (fileName, showmessgae) => {
    if (isPreviewResponse) {
      return;
    }
    if (showmessgae !== false) {
      setIsLoaded(true);
      if (showmessgae === undefined || showmessgae === null) {
        showmessgae = true;
      }
      const url = `${DELETE_PMA_ADDITIONAL_RESPONSE}?caseCode=${props.caseDetails["caseCode"]}&fileName=${fileName}`;
      const body = {
        caseCode: parseInt(props.caseDetails["caseCode"]),
      };
      axios
        .post(url, body, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        })
        .then((res1) => {
          if (res1.status === 200) {
            const additinalfiledata = additionalFile;
            const indexadditinalfiledata = additinalfiledata.findIndex(
              (x) => x.fileName === fileName
            );
            additinalfiledata.splice(indexadditinalfiledata, 1);
            setAdditionalFile(additinalfiledata);
            if (fileName === markAsPmaFile) {
              setMarkAsPmaFile("");
              setMarkAsPmaUploadFile("");
              setMarkAsPmaDecisioFile("");
              setdecisionFileAvailable(false);
            }
            const url1 = `${DELETE_ADDITIONAL_RESPONSE}/${props.caseDetails["caseReference"]}/${fileName}`;
            axios
              .get(url1, {
                headers: {
                  Authorization: `Bearer ${localStorage.getItem("token")}`,
                },
              })
              .then((res3) => {
                if (res3.status === 200) {
                  //setresponse(null);
                  if (isShowDeleteMessage && showmessgae) {
                    setdeletedResponse(true);
                    setdeletedResponseMsg(
                      "Additional Document has been deleted successfully"
                    );
                  }
                }
              });
          }
          setIsLoaded(false);
        });
    } else {
      const url1 = `${DELETE_ADDITIONAL_RESPONSE}/${props.caseDetails["caseReference"]}/${fileName}`;
      axios
        .get(url1, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        })
        .then((res3) => {
          if (res3.status === 200) {
            //   setresponse(null);
            if (isShowDeleteMessage && showmessgae) {
              setdeletedResponseMsg(
                "Additional Document has been deleted successfully"
              );
              //console.log(markAsPmaFile);
              setdeletedResponse(true);
            }
          }
        });
    }
  };

  const deleteOnCancelPreview = async (
    fileName,
    showmessgae,
    reCall = false
  ) => {
    if (isPreviewResponse) {
      return;
    }
    const url = `${DELETE_PMA_ADDITIONAL_RESPONSE}?caseCode=${props.caseDetails["caseCode"]}&fileName=${fileName}&reCall=${reCall}`;
    const body = {
      caseCode: parseInt(props.caseDetails["caseCode"]),
    };
    axios
      .post(url, body, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then((res1) => {
        if (res1.status === 200) {
          const additinalfiledata = additionalFile;
          const indexadditinalfiledata = additinalfiledata.findIndex(
            (x) => x.fileName === fileName
          );
          if (
            indexadditinalfiledata === -1 &&
            Utility.IsCaseDecision(props.caseDetails["caseStatus"])
          ) {
            setdecisionFileAvailable(false);
          }
          if (indexadditinalfiledata !== -1) {
            additinalfiledata.splice(indexadditinalfiledata, 1);
            setAdditionalFile(additinalfiledata);
            if (fileName === markAsPmaFile) {
              setMarkAsPmaFile("");
              setMarkAsPmaUploadFile("");
              setMarkAsPmaDecisioFile("");
              setdecisionFileAvailable(false);
            }
          }
        }
      })
      .catch(function (error) {
        setMarkAsPmaFile("");
        setMarkAsPmaUploadFile("");
        setMarkAsPmaDecisioFile("");
        setdecisionFileAvailable(false);
      });
    const url1 = `${DELETE_ADDITIONAL_RESPONSE}/${props.caseDetails["caseReference"]}/${fileName}`;
    axios
      .get(url1, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then((res3) => {
        if (res3.status === 200) {
          //   setresponse(null);
          if (isShowDeleteMessage && showmessgae) {
            setdeletedResponseMsg(
              "Additional Document has been deleted successfully"
            );
            //console.log(markAsPmaFile);
            setdeletedResponse(true);
          }
        }
      });
  };

  const closeConfirmDialog = (value) => {
    if (value === true && deleteIndexDefendantPantiff > -1) {
      removeErrorMessage(deleteIndexDefendantPantiff, true);
    }
    setOpenConfirmDialog(false);
    setIsConfirmedDelete(value);
    setDeleteMessage("");
    var _decisionFile = markAsPmaDecisioFile;
    if (isResubmit && value === true) {
      setPMADetails({ ...pmaDetails, status: "" });
      setBtnHandle(false);
      const url = `${voidedDocusign}`;
      let file = additionalFile.findIndex((obj) => {
        return obj.fileName === _decisionFile;
      });
      const fileList = additionalFile;
      setMarkAsPmaFile(null);
      setMarkAsPmaUploadFile(null);
      setMarkAsPmaDecisioFile(null);
      setdecisionFileAvailable(false);

      deleteOnCancelPreview(_decisionFile, false, true);

      setIsResubmit(!isResubmit);
      // Call Voided Envelope docusign method
      axios
        .post(url, JSON.stringify(voidenvelopeId))
        .then((response3) => {
          if (response3.status === 200 || response3.status === 204) {
            setEnvelopeId("");
            window.location.reload();
          }
        })
        .catch((error) => {
          setdisplayAlertError(true);
          setIsLoaded(false);
        });
    }
  };

  const onClickApplyDecisionTemplate = () => {
    setShowDecisionTemplateDialog(true);
  };

  const onSelectionChange = (selectedCases) => {
    if (selectedCases.length > 0) {
      let dataArray = [];
      selectedCases.forEach((element) => {
        dataArray.push(element.id);
      });
      setSelectedItems(dataArray);
    } else {
      setSelectedItems([]);
    }
  };

  const applyDecisionToMassFileCases = async () => {
    setIsApplyingDecisionTemplateToCases(true);

    const casesList = massFileCases.filter((x) =>
      selectedItems.includes(x.caseId)
    );

    console.log(casesList);

    const promises = casesList.map(async (item) => {
      return await getPmaDetailsForMassFileCases(props.caseDetails["caseCode"]); // Use async/await to process each item
    });

    // Wait for all promises to resolve
    const results = await Promise.all(promises);
    setIsApplyingDecisionTemplateToCases(false);
    setIsCasesSelectedForDecisionApply(true);

    setPmaDetailsForSelectedCases(results);
  };

  const onClickPreviewDocumen = () => {
    setIsApplyingDecisionTemplateToCases(true);
    setShowDecisionTemplateDialog(false);
    submitHandlerForMassFile();
    setIsApplyingDecisionTemplateToCases(false);
  };

  const onCloseShowDecisionTemplateDialog = () => {
    if (isApplyingDecisionTemplateToCases) {
      return;
    }

    setShowDecisionTemplateDialog(false);
  };

  const getShowApplyTemplate = () => {
    if (
      props.hearingDetails?.rowData?.massFileId &&
      !selectedCaseForDecisionApply
    ) {
      const pmaDetailsForMassFileCases = localStorage.getItem(
        "pmaDetailsForMassFileCases"
      )
        ? decryptItem("pmaDetailsForMassFileCases")
        : [];

      const hasAnyCase = massFileCases.find((x) => {
        const isDocSent =
          pmaDetailsForMassFileCases.find(
            (pm) => pm.caseCode.toString() === x.caseCode.toString()
          )?.status === "sent";

        return x.isDecisionExist !== true || !isDocSent;
      });

      return hasAnyCase && decisionFileAvailable;
    }

    return (
      props.hearingDetails?.rowData?.massFileId &&
      !selectedCaseForDecisionApply &&
      (decisionFileAvailable ||
        (Object.keys(plainTiffDefentError).length > 0 &&
          btnHandle === false &&
          caseUnsettled === false)) &&
      !(pmaDetails.status === "sent")
    );
  };

  console.log(props.hearingDetails);
  console.log(props.caseDetails, "caseDetails");
  console.log(!isFileUpload, "isFileUpload");
  console.log(TimesheetNotSubmitted, "TimesheetNotSubmitted");
  console.log(isFormdisabled, "isFormdisabled");

  return (
    <div
      className={`${
        decisionFileAvailable || isFormdisabled ? "hide--required" : ""
      }  ${btnHandle || caseUnsettled ? "hideerror" : ""}  ${
        caseUnsettled ? "hide--required" : ""
      }`}
    >
      {/* <div> */}
      <LoadingOverlay
        active={isLoaded || isApplyingDecisionTemplateToCases}
        spinner
        text="Please wait working on your request..."
      >
        {showDecisionTemplateDialog && (
          <Modal
            show={true}
            className="modal-decisionTemplateDialog"
            contentClassName="modal-decisionTemplateDialogContent center"
            onHide={() => onCloseShowDecisionTemplateDialog()}
          >
            <Modal.Header
              closeButton
              onHide={onCloseShowDecisionTemplateDialog}
            >
              <Modal.Title>
                <div>
                  {isCasesSelectedForDecisionApply && (
                    <span
                      style={{
                        fontSize: "12px",
                        marginRight: "15px",
                        cursor: "pointer",
                      }}
                      onClick={() => setIsCasesSelectedForDecisionApply(false)}
                    >
                      {`< Go back `}
                    </span>
                  )}
                  <span>
                    {isCasesSelectedForDecisionApply
                      ? ` Hearing Officer: ${
                          props.hearingDetails?.rowData?.hearingOfficerName
                        } ${
                          props.hearingDetails?.rowData?.hearingOfficerEmail
                            ? props.hearingDetails?.rowData?.hearingOfficerEmail
                            : ""
                        }`
                      : " Apply Decision Template"}
                  </span>
                </div>
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              {isCasesSelectedForDecisionApply ? (
                <PreviewESignDocumentsForSelectedCases
                  selectedCases={selectedItems}
                />
              ) : (
                <ApplyDecisionTemplateCases
                  onSelectionChange={onSelectionChange}
                  isLoading={isApplyingDecisionTemplateToCases}
                />
              )}
            </Modal.Body>
            <Modal.Footer className="modal-decisionTemplateDialogFooter">
              {isCasesSelectedForDecisionApply ? (
                <Button
                  variant="secondary"
                  disabled={selectedItems.length <= 0}
                  onClick={onClickPreviewDocumen}
                >
                  Preview e-Signature Document
                </Button>
              ) : (
                <Button
                  variant="secondary"
                  disabled={
                    selectedItems.length <= 0 ||
                    isApplyingDecisionTemplateToCases
                  }
                  onClick={applyDecisionToMassFileCases}
                >
                  Apply Decision
                </Button>
              )}
            </Modal.Footer>
          </Modal>
        )}

        <div className="decision-page">
          {deletedResponse && (
            <Modal
              show={deletedResponse}
              onHide={() => setdeletedResponse(false)}
            >
              <Modal.Header closeButton>
                <Modal.Title>Decision Document</Modal.Title>
              </Modal.Header>
              <Modal.Body>{deletedResponseMsg}</Modal.Body>
              <Modal.Footer>
                <Button
                  variant="secondary"
                  onClick={() => setdeletedResponse(false)}
                >
                  Close
                </Button>
              </Modal.Footer>
            </Modal>
          )}

          {isPreview && (
            <div
              className="col-10"
              style={{ marginTop: "1rem" }}
            >
              <Modal
                show={isPreview}
                className="decmodal-content"
                contentClassName="modal-classContent center"
                onHide={cancelPreview}
              >
                <Modal.Header closeButton>
                  <Modal.Title>Preview Document</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  {/* New button Code... */}
                  {isPreview && !isPreviewResponse && filebase64 && (
                    <div
                      className="mb-1s row"
                      style={{ marginBottom: "5px" }}
                    >
                      <div className="col-10">
                        <button
                          className="pmabtn3"
                          style={{ marginRight: "-11.5rem" }}
                          onClick={
                            props.hearingDetails?.rowData?.massFileId &&
                            !selectedCaseForDecisionApply
                              ? sendForDocSignForMassFile
                              : sendForDocusign
                          }
                        >
                          <i
                            className="fa fa-check-circle"
                            aria-hidden="true"
                          ></i>
                          Send For Docusign
                        </button>
                        <button
                          className="float-right pmabtn4"
                          style={{ marginRight: "1rem" }}
                          onClick={cancelPreview}
                        >
                          <i
                            className="fa fa-times-circle"
                            style={{ color: "red", marginRight: "10px" }}
                            aria-hidden="true"
                          ></i>
                          Cancel
                        </button>
                      </div>
                    </div>
                  )}
                  {/* New button Code End... */}
                  <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.2.228/build/pdf.worker.min.js">
                    <div style={{ height: "720px" }}>
                      <Viewer
                        fileUrl={
                          isPreviewResponse ? PreviewResponse : filebase64
                        }
                        plugins={[defaultInstance]}
                      />
                    </div>
                  </Worker>
                </Modal.Body>
              </Modal>
            </div>
          )}

          <div>
            <div>
              <div
                className="text-left font-weight-bold"
                style={{ padding: "5px 0px 7px" }}
              >
                Please fill in the below details
              </div>
            </div>
            <div
              className="casestructure"
              id="htmldoc"
            >
              <CaseData
                caseDetails={props.caseDetails}
                subGroupId={props?.hearingDetails?.rowData?.subGroupId}
              />

              <div className="row mb-1s">
                <div className="col-12">
                  {isSubmitted && isDocuSigned && filebase64 ? (
                    <button
                      className="pmabtn3"
                      disabled={true}
                      style={{ marginRight: "1rem" }}
                    >
                      <i
                        className="fa fa-check-circle"
                        aria-hidden="true"
                      ></i>
                      Submitted
                    </button>
                  ) : pmaDetails.status === "sent" && !isDocuSigned ? (
                    <button
                      className="float-right pmabtn2"
                      disabled={true}
                      style={{ marginRight: "1rem" }}
                    >
                      <i
                        className="fa fa-exclamation-circle"
                        aria-hidden="true"
                      ></i>
                      Pending for E-Signature
                    </button>
                  ) : (
                    ""
                  )}
                  {/* Resubmitt form Code */}
                  {(decisionFileAvailable && !isDocuSigned && voidenvelopeId) ||
                  (!isDocuSigned &&
                    voidenvelopeId &&
                    !Utility.IsCaseDecision(
                      props.caseDetails["caseStatus"]
                    )) ? (
                    <button
                      className={"pmaButton btn-primary"}
                      style={{
                        float: "right",
                        minWidth: "130px",
                        margin: ".05rem 1rem 0",
                      }}
                      onClick={() => {
                        // deleteAdditionalResponse(`${props.caseDetails["caseReference"]}` + `_decision.pdf`);
                        // deleteOnCancelPreview(`${props.caseDetails["caseReference"]}` + `_decision.pdf`, false);
                        setOpenConfirmDialog(true);
                        setIsResubmit(true);

                        //setAdditionalFileRemove(`${props.caseDetails["caseReference"]}` + `_decision.pdf`);
                        setDeleteMessage(
                          `This will require the form to be reissued and signed again by the required signatories.`
                        );
                      }}
                    >
                      Recall Decision
                    </button>
                  ) : (
                    ""
                  )}
                  {/* Resubmitt form Code End */}
                </div>
              </div>
            </div>
            <>
              <>
                <div className="row">
                  {/* {ifnotMarkAsPMA && file.length > 0 && (
                        <label style={{ color: "red" }}>
                          Please mark at least one file as Decision
                        </label>
                      )} */}
                  <div style={{ textAlign: "left" }}>
                    <button
                      className={
                        switchUploads ? "historybtn2" : "uploadbtn active"
                      }
                      onClick={handleswitchNewUploads}
                    >
                      Decision
                    </button>
                    <button
                      className={
                        switchUploads ? "historybtn active" : "historybtn2"
                      }
                      onClick={handleSwitchHistory}
                    >
                      History
                    </button>
                  </div>
                </div>

                <div
                  className={
                    voidenvelopeId &&
                    !isDocuSigned &&
                    !switchUploads &&
                    !isFormscheduled
                      ? "border pmasec disablediv "
                      : "border pmasec"
                  }
                >
                  {/* this code is commented out because of no need to show consolidated decision file */}
                  {/* {switchUploads &&
                                        (response === null ||
                                            response === undefined ||
                                            response === "") && (
                                            <>
                                                <div
                                                    className="row"
                                                    style={{
                                                        borderBottom: "1px solid",
                                                        borderBottomColor: "lightgrey",
                                                        borderTop: "1px solid",
                                                        borderTopColor: "lightgrey",
                                                    }}
                                                >
                                                    <div className="col-md-4 col-3 text-left">
                                                        Signed & Consolidated Documents
                                                    </div>
                                                    <div className="col-md-4 text-left">
                                                        Date Uploaded
                                                    </div>
                                                    <div className="col-md-4 text-left">Action(s)</div>
                                                    <hr style={{ width: "90%" }} className="solid" />
                                                    <div className="col-md-4 col-9 response-submission">
                                                        <div className="">
                                                            <span>Decision not submitted</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </>
                                        )} */}

                  {switchUploads && !voidenvelopeId && (
                    <>
                      <div
                        className="row"
                        style={{
                          borderBottom: "1px solid",
                          borderBottomColor: "lightgrey",
                          borderTop: "1px solid",
                          borderTopColor: "lightgrey",
                        }}
                      >
                        <div className="col-md-4 col-3 text-left">
                          Decision Documents
                        </div>
                        <div className="col-md-4 text-left">Date Uploaded</div>
                        <div className="col-md-4 text-left">Action(s)</div>
                        <hr
                          style={{ width: "90%" }}
                          className="solid"
                        />
                        <div className="col-md-4 col-9 response-submission">
                          <div className="">
                            <span>Decision not submitted</span>
                          </div>
                        </div>
                      </div>
                    </>
                  )}

                  {switchUploads && voidenvelopeId && (
                    <>
                      <div
                        className="row"
                        style={{
                          borderBottom: "1px solid",
                          borderBottomColor: "lightgrey",
                        }}
                      >
                        <div className="col-sm-4 text-left">
                          Decision Documents
                        </div>
                        <div className="col-md-4 text-left">Date Uploaded</div>
                        <div className="col-md-4 text-left">Action(s)</div>
                        <hr
                          style={{ width: "90%" }}
                          className="solid"
                        />

                        <div className="col-md-4 response-submission text-left">
                          <span className="action">
                            {" "}
                            <i
                              style={{ color: "#DC1D00" }}
                              className="fa fa-file-pdf-o"
                            ></i>
                          </span>
                          <span className="file-name">
                            {`${props.caseDetails["caseReference"]}.pdf`}
                            &nbsp; &nbsp; &nbsp; &nbsp;
                          </span>
                        </div>
                        <div className="col-md-4 response-submission text-left">
                          <span>
                            {" "}
                            {Utility.getdate(pmaDetails.decisionFileUpdate)}
                          </span>
                        </div>
                        <div className="col-md-4 response-submission text-left">
                          <span
                            className="action"
                            onClick={() =>
                              viewResponse(
                                `${props.caseDetails["caseReference"]}_decision.pdf`
                              )
                            }
                          >
                            <i className="fa fa-eye"></i>
                          </span>

                          <span
                            className="action"
                            onClick={() => {
                              downloadResponse(
                                `${props.caseDetails["caseReference"]}_decision.pdf`
                              );
                            }}
                          >
                            <i className="fa fa-download"></i>
                          </span>
                          {!(
                            (decisionFileAvailable &&
                              !isDocuSigned &&
                              voidenvelopeId) ||
                            (!isDocuSigned && voidenvelopeId)
                          ) && (
                            <span
                              className="action"
                              onClick={() => {
                                //// deleteResponse
                                if (!isDocuSigned) {
                                  setOpenConfirmDialog(true);
                                  setCaseReference(true);
                                  setDeleteMessage(
                                    `Are you sure you want to delete this file: ${props.caseDetails["caseReference"]}.pdf`
                                  );
                                }
                              }}
                            >
                              <i className="fa fa-trash"></i>
                            </span>
                          )}
                        </div>
                      </div>
                    </>
                  )}
                  {/* History */}
                  {switchUploads && additionalFile && (
                    <div
                      className=""
                      id="htmldoc2"
                    >
                      <div className="row">
                        <div className="col-md-4 text-left">
                          Additional Document(s)
                        </div>
                        <div className="col-md-4 text-left">Date Uploaded</div>
                        <div className="col-md-4 text-left">Action(s)</div>
                        <hr
                          style={{ width: "90%" }}
                          className="solid"
                        />
                        {additionalFile !== null &&
                        additionalFile.length > 0 &&
                        isFileSendForDocusign ? (
                          additionalFile?.map((item, index) => {
                            return (
                              markAsPmaFile !== item.name && (
                                <div
                                  className="row"
                                  style={{ margin: "0", padding: "0" }}
                                >
                                  <div
                                    className="col-md-4 response-submission text-left"
                                    style={{
                                      color: item?.isOnlineDecisionFile
                                        ? "blue"
                                        : "gray",
                                    }}
                                  >
                                    <span className="action">
                                      {" "}
                                      <i
                                        style={{
                                          color:
                                            item?.fileName?.indexOf(".pdf") !==
                                            -1
                                              ? "#DC1D00"
                                              : "blue",
                                        }}
                                        className={
                                          item?.fileName?.indexOf(".pdf") !== -1
                                            ? "fa fa-file-pdf-o"
                                            : "fa fa-file-word-o"
                                        }
                                      ></i>
                                    </span>
                                    <span className="file-name">
                                      {item?.fileName === markAsPmaFile
                                        ? "[Decision File]"
                                        : ""}
                                      {item.fileName}
                                    </span>
                                  </div>
                                  {/* <span className="action" onClick={(e) => removeFile(e, item, index)}> */}
                                  <div className="col-md-4 response-submission text-left">
                                    {Utility.getdate(item.updateDate)}
                                  </div>
                                  <div className="col-md-4 response-submission text-left">
                                    &nbsp; &nbsp;
                                    <span
                                      className="action"
                                      onClick={() =>
                                        viewResponse(item.fileName)
                                      }
                                    >
                                      <i className="fa fa-eye"></i>
                                    </span>
                                    &nbsp; &nbsp;
                                    <span
                                      className="action"
                                      onClick={() => {
                                        downloadResponse(item.fileName);
                                      }}
                                    >
                                      <i className="fa fa-download"></i>
                                    </span>{" "}
                                    &nbsp; &nbsp;
                                    {!(
                                      (decisionFileAvailable &&
                                        !isDocuSigned &&
                                        voidenvelopeId) ||
                                      (!isDocuSigned && voidenvelopeId)
                                    ) && (
                                      <span
                                        className="action"
                                        onClick={() => {
                                          // deleteAdditionalResponse(item.fileName);
                                          setOpenConfirmDialog(true);
                                          setAdditionalFileRemove(
                                            item.fileName
                                          );
                                          setDeleteMessage(
                                            `Are you sure you want to delete this file: ${item.fileName}`
                                          );
                                        }}
                                      >
                                        <i className="fa fa-trash"></i>
                                      </span>
                                    )}
                                  </div>
                                </div>
                              )
                            );
                          })
                        ) : (
                          <div className="col-md-4">
                            No additional document(s) are found
                          </div>
                        )}
                      </div>
                    </div>
                  )}
                  {switchUploads === false && isDocuSigned && (
                    <>
                      <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.2.228/build/pdf.worker.min.js">
                        <div style={{ height: "720px" }}>
                          <Viewer
                            fileUrl={filebase64}
                            plugins={[defaultInstance]}
                          />
                        </div>
                      </Worker>
                    </>
                  )}
                  {/* Decision */}
                  {switchUploads === false && !isDocuSigned && (
                    <>
                      <div>
                        <div
                          className=""
                          id="htmldoc2"
                        >
                          <div>
                            {props.caseDetails["isPaid"] ? (
                              <></>
                            ) : !Utility.IsCaseDecision(
                                props.caseDetails["caseStatus"]
                              ) && TimesheetNotSubmitted ? (
                              <div className="row">
                                <div
                                  style={{ display: "flex", textAlign: "left" }}
                                >
                                  <span style={{ color: "red" }}>
                                    You must submit timesheet before proceeding
                                    with the Decision submission.
                                  </span>
                                </div>
                              </div>
                            ) : (
                              <></>
                            )}
                            {!Utility.IsCaseClosed(
                              props.caseDetails["caseStatus"]
                            ) &&
                              decisionFileAvailable !== null &&
                              decisionFileAvailable !== "" &&
                              decisionFileAvailable !== undefined &&
                              decisionFileAvailable === true && (
                                <div className="row">
                                  <div
                                    style={{
                                      display: "flex",
                                      textAlign: "left",
                                    }}
                                  >
                                    <span style={{ color: "red" }}>
                                      The on-line decision form is unavailable
                                      as a decision document has already been
                                      uploaded.
                                      <br />
                                      If required, please use File History below
                                      to delete the uploaded decision document.
                                    </span>
                                  </div>
                                </div>
                              )}
                            {/* Case Unresolved */}
                            <div className="row">
                              <div style={{ display: "flex" }}>
                                <input
                                  type="checkbox"
                                  style={{ marginRight: "5px" }}
                                  onChange={onCaseUnsettledChange}
                                  disabled={isFormdisabled}
                                  checked={caseUnsettled}
                                  //checked={isHearingsubmitted ?caseUnsettled: (caseUnsettled && isHearingSentForDocusign)}
                                />
                                Case Unresolved
                              </div>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-md-2 text-left">
                              <span>Meeting attendees - </span>
                            </div>
                            <input
                              type="text"
                              id="focusInput"
                              onBlur={SaveData}
                            />
                            <div className="col-md-9 text-left">
                              <input
                                type="text"
                                className="form-control"
                                name="meetingattendies"
                                onKeyDown={onkeydown}
                                onChange={changeAttendies}
                                value={attendies}
                                onBlur={SaveData}
                                ref={ref}
                                disabled={
                                  decisionFileAvailable || isFormdisabled
                                }
                              />
                            </div>
                          </div>
                          <div>
                            <span className="text-left">
                              <div className="rightt">
                                {`The arbitrator, ${props.caseDetails["hearingOfficerName"]}, hereby makes the following findings:`}
                              </div>
                              <div className="row">
                                {/* <div className="col-md-12">Dated at <strong>{Utility.getdate(
                                props.caseDetails["hearingDate"]
                              )}</strong></div> */}
                              </div>
                              <div
                                className="row"
                                style={{ marginBottom: ".5rem" }}
                              >
                                <div className="col-md-2">
                                  The issue(s) to be decided upon are
                                </div>
                                <div className="col-md-9">
                                  <select
                                    name="IssueName"
                                    disabled={
                                      decisionFileAvailable || isFormdisabled
                                    }
                                    className="SelectField2 form-control"
                                    onChange={onIssueNameChangeHandler}
                                    value={
                                      pmaDetails.issueName !== undefined
                                        ? pmaDetails.issueName
                                        : ""
                                    }
                                    onBlur={SaveData}
                                  >
                                    <option value="Liability and Damages">
                                      Both (Liability and Damages)
                                    </option>
                                    <option value="Contract Dispute">
                                      Contract Dispute
                                    </option>
                                    <option value="Damages">Damages</option>
                                    <option value="Damages Only">
                                      Damages Only
                                    </option>
                                    <option value="Liability">Liability</option>
                                    <option value="Other">Other</option>
                                    <option value="Warehouse Liability">
                                      Warehouse Liability
                                    </option>
                                  </select>
                                </div>
                              </div>

                              <div
                                className="row"
                                style={{ marginBottom: "0.5rem" }}
                              >
                                <div className="col-md-2">
                                  Decision Details{" "}
                                  <span
                                    style={{ color: "#f00" }}
                                    className="custom--required"
                                  >
                                    *&nbsp; &nbsp;
                                  </span>
                                </div>
                                <div className="col-md-9">
                                  <div
                                    onBlur={(e) => {
                                      SaveData(e);
                                      setPMADetails({
                                        ...pmaDetails,
                                        decision:
                                          extractTextFromHTML(
                                            pmaDetails.decision
                                          ) == ""
                                            ? ""
                                            : pmaDetails.decision,
                                      });
                                    }}
                                  >
                                    <F1GrammarCheckerTextArea
                                      key="decisiondetailskey"
                                      type="textarea"
                                      rows="6"
                                      id="decisiondetailsid"
                                      num_retries={5}
                                      buttonBackgroundColor="#a55cec"
                                      buttonText={null}
                                      buttonTextColor="white"
                                      grammarChecking={true}
                                      grammarProvider="AzureOpenAI"
                                      grammarProviderURI="https://spellcheckgpt4.openai.azure.com/"
                                      grammarProviderEndpointName="gpt-4o"
                                      grammarProviderAPIVersion="2023-03-15-preview"
                                      azureApiKey="425311175a3c4ca49545a7ad1cd1e5df"
                                      customEditorClassName="decisiondetailsclass"
                                      setTextData={setTextData}
                                      textData={textData1}
                                      disabled={
                                        decisionFileAvailable ||
                                        isFormdisabled ||
                                        isHearingSentForDocusign
                                      }
                                    />
                                  </div>

                                  {/* <textarea
                                                                        style={{
                                                                            width: "100%",
                                                                            lineHeight: "20px",
                                                                            borderColor:`${hilightDecisionDetails? "red":"grey"}`
                                                                        }}
                                                                        disabled={decisionFileAvailable || isFormdisabled}
                                                                        name="decisionDetails"
                                                                        value={pmaDetails.decision}
                                                                        spellCheck={true}
                                                                        onChange={decisionDetailshandler}
                                                                        required={true}
                                                                        onBlur={(e) => {
                                                                            SaveData(e);
                                                                            setPMADetails({
                                                                                ...pmaDetails,
                                                                                decision: pmaDetails.decision,
                                                                            });
                                                                        }}
                                                                    /> */}
                                </div>
                              </div>
                              <div className="row">
                                <div className="col-md-2 col-12">
                                  Net Amount Award{" "}
                                  <span
                                    style={{
                                      color: "#f00",
                                      display: "contents",
                                    }}
                                    className="custom--required"
                                  >
                                    *
                                  </span>
                                </div>
                                <div className="col-md-9 col-12">
                                  <span
                                    style={{ color: "red" }}
                                    className="error-message error"
                                  >
                                    {message}
                                  </span>
                                  <div
                                    className=""
                                    style={{
                                      display: "flex",
                                      flexDirection: "column",
                                    }}
                                  >
                                    {pmaDetails.participants !== undefined &&
                                    pmaDetails.participants !== null ? (
                                      pmaDetails.participants.map(
                                        (item, index) => {
                                          return (
                                            <>
                                              <div>
                                                <div
                                                  className={`add-participants`}
                                                >
                                                  <input
                                                    style={{
                                                      marginRight: "1rem",
                                                      width: "40%",
                                                      borderColor: `${
                                                        item.HilightDefendent
                                                          ? "red"
                                                          : "grey"
                                                      }`,
                                                    }}
                                                    required
                                                    type="text"
                                                    disabled={
                                                      decisionFileAvailable ||
                                                      isFormdisabled
                                                    }
                                                    placeholder="Defendant Name"
                                                    value={item.defendantName}
                                                    onChange={(event) =>
                                                      onDefendantChange(
                                                        event,
                                                        index
                                                      )
                                                    }
                                                    onBlur={SaveData}
                                                    onMouseLeave={SaveData}
                                                  />
                                                  <input
                                                    style={{
                                                      marginRight: "1rem",
                                                      width: "40%",
                                                      borderColor: `${
                                                        item.hilightPlantiff
                                                          ? "red"
                                                          : "grey"
                                                      }`,
                                                    }}
                                                    required
                                                    type="text"
                                                    disabled={
                                                      decisionFileAvailable ||
                                                      isFormdisabled
                                                    }
                                                    placeholder="Plaintiff Name"
                                                    value={item.plaintiffName}
                                                    onChange={(event) =>
                                                      onPlaintiffNameChange(
                                                        event,
                                                        index
                                                      )
                                                    }
                                                    onBlur={SaveData}
                                                    onMouseLeave={SaveData}
                                                  />

                                                  <div className="dollerDiv">
                                                    <p className="amountInput">
                                                      ${" "}
                                                      <NumberFormat
                                                        allowNegative={false}
                                                        onValueChange={(
                                                          event
                                                        ) => {
                                                          onValueChange(
                                                            event,
                                                            index
                                                          );
                                                          handleAmtPayError(
                                                            event,
                                                            "m" + index
                                                          );
                                                        }}
                                                        disabled={
                                                          decisionFileAvailable ||
                                                          isFormdisabled
                                                        }
                                                        required
                                                        thousandSeparator={true}
                                                        name="amtPayable"
                                                        decimalScale={2}
                                                        fixedDecimalScale={true}
                                                        value={item.amount}
                                                        onBlur={SaveData}
                                                        thousandsGroupStyle={
                                                          true
                                                        }
                                                      />
                                                    </p>
                                                    {plainTiffDefentError &&
                                                      plainTiffDefentError.hasOwnProperty(
                                                        "m" + index
                                                      ) && (
                                                        <span
                                                          className={`error-message cerror`}
                                                          style={{
                                                            color: "red",
                                                          }}
                                                        >
                                                          {
                                                            plainTiffDefentError[
                                                              "m" + index
                                                            ]
                                                          }
                                                        </span>
                                                      )}
                                                  </div>
                                                  {!decisionFileAvailable &&
                                                    !isFormdisabled && (
                                                      <span
                                                        className="action"
                                                        onClick={() => {
                                                          // deleteParticipants(index);

                                                          setOpenConfirmDialog(
                                                            true
                                                          );
                                                          setDeleteIndexDefendantPantiff(
                                                            index
                                                          );

                                                          //document.getElementById("focusInput").focus();
                                                          setDeleteMessage(
                                                            "Are you sure you want to delete this record ?"
                                                          );
                                                        }}
                                                      >
                                                        <i className="fa fa-trash"></i>
                                                      </span>
                                                    )}
                                                </div>
                                                {item.errorMessage !== "" && (
                                                  <div>
                                                    <span
                                                      style={{ color: "red" }}
                                                      className="error-message error"
                                                    >
                                                      {item.errorMessage}
                                                    </span>
                                                  </div>
                                                )}
                                              </div>
                                            </>
                                          );
                                        }
                                      )
                                    ) : (
                                      <></>
                                    )}
                                  </div>
                                  {!decisionFileAvailable && (
                                    <button
                                      className={`additionalbtn2 ${
                                        decisionFileAvailable || isFormdisabled
                                          ? "disabledBtn"
                                          : ""
                                      }`}
                                      disabled={
                                        decisionFileAvailable || isFormdisabled
                                      }
                                      onClick={handleAddContainer}
                                    >
                                      +Add
                                    </button>
                                  )}
                                </div>
                              </div>
                              {totalSum !== "0.00" && (
                                <div
                                  className="row"
                                  style={{ marginBottom: ".5rem" }}
                                >
                                  <div className="col-md-8"></div>
                                  <div className="col-md-4">
                                    <b>Total Amount Awarded</b>:{" "}
                                    <b>
                                      ${" "}
                                      <NumberFormat
                                        allowNegative={false}
                                        disabled={true}
                                        style={{ width: "13%" }}
                                        thousandSeparator={true}
                                        decimalScale={2}
                                        fixedDecimalScale={true}
                                        value={totalSum}
                                        displayType={"text"}
                                      />
                                    </b>
                                  </div>
                                </div>
                              )}

                              <div
                                className="row"
                                style={{ marginBottom: ".5rem" }}
                              >
                                <div className="col-md-2">By</div>
                                <div className="col-md-9">
                                  <input
                                    type="text"
                                    style={{ width: "100%" }}
                                    placeholder={
                                      props.caseDetails["hearingOfficerName"]
                                    }
                                    disabled={true}
                                  />
                                </div>
                              </div>

                              {pmaDetails.timeSheets !== undefined &&
                                pmaDetails.timeSheets !== null &&
                                pmaDetails.timeSheets.length > 0 && (
                                  <>
                                    <div>
                                      <span style={{ fontWeight: "bold" }}>
                                        Timesheet Details:
                                      </span>

                                      <table className="decision-timesheet table1">
                                        <thead>
                                          <tr>
                                            <th scope="col">Hearing date</th>
                                            {/* <th scope="col">Start time</th>
                                                                                        <th scope="col">End time</th> */}
                                            <th scope="col">Time (ET)</th>

                                            {/* {!props.caseDetails
                                              .isCommercialCase && (
                                              <th scope="col">Reserved Time</th>
                                            )} */}
                                            {!props.caseDetails
                                              .isCommercialCase && (
                                              <th scope="col">
                                                Document review/Decision
                                                preparation
                                              </th>
                                            )}
                                            <th scope="col">
                                              Total hearing time
                                            </th>
                                          </tr>
                                        </thead>
                                        <tbody className="font-weight-bold">
                                          {pmaDetails.timeSheets.map(
                                            (timesheet, index) => {
                                              return (
                                                <>
                                                  <tr
                                                    // style={{
                                                    //     display: !props.caseDetails
                                                    //         .isCommercialCase && caseUnsettled
                                                    //         ? timesheet.hearingCode ===
                                                    //             hearingCode
                                                    //             ? "table-row"
                                                    //             : "none"
                                                    //         : "table-row",
                                                    // }}
                                                    className={`${
                                                      timesheet.hearingCode ==
                                                      hearingCode
                                                        ? "current-hearing"
                                                        : "prev-hearing"
                                                    }`}
                                                  >
                                                    <td data-label="Hearing Date">
                                                      {Utility.getdate(
                                                        timesheet.hearingDate
                                                      )}
                                                    </td>
                                                    {/* {!props.caseDetails
                                                                                                            .isCommercialCase ? (
                                                                                                            <td data-label="Start Time">
                                                                                                                {Utility.ConvertDate(
                                                                                                                    timesheet.hearingStartTime
                                                                                                                )}
                                                                                                            </td>
                                                                                                        ) : (
                                                                                                            <td></td>
                                                                                                        )} */}
                                                    {!props.caseDetails
                                                      .isCommercialCase ? (
                                                      <td data-label="Time">
                                                        {timesheet.isCombinedTimeSheet ? (
                                                          <span>
                                                            <span>
                                                              {"Session 1 : " +
                                                                (timesheet.hearingStartTimeUTC &&
                                                                  new Date(
                                                                    timesheet.hearingStartTimeUTC.indexOf(
                                                                      "GMT"
                                                                    )
                                                                      ? Utility.convertUTCDateToUserTimeZoneOfPMADecision(
                                                                          timesheet.hearingStartTimeUTC
                                                                        )
                                                                      : Utility.convertUTCDateToUserTimeZoneOfPMADecision(
                                                                          new Date(
                                                                            timesheet.hearingStartTimeUTC
                                                                          ).toUTCString()
                                                                        )
                                                                  )
                                                                    .toLocaleTimeString()
                                                                    .replace(
                                                                      /([\d]+:[\d]{2})(:[\d]{2})(.*)/,
                                                                      "$1$3"
                                                                    )) +
                                                                " to " +
                                                                (timesheet.hearingEndTimeUTC &&
                                                                  new Date(
                                                                    timesheet.hearingEndTimeUTC.indexOf(
                                                                      "GMT"
                                                                    )
                                                                      ? Utility.convertUTCDateToUserTimeZoneOfPMADecision(
                                                                          timesheet.hearingEndTimeUTC
                                                                        )
                                                                      : Utility.convertUTCDateToUserTimeZoneOfPMADecision(
                                                                          new Date(
                                                                            timesheet.hearingEndTimeUTC
                                                                          ).toUTCString()
                                                                        )
                                                                  )
                                                                    .toLocaleTimeString()
                                                                    .replace(
                                                                      /([\d]+:[\d]{2})(:[\d]{2})(.*)/,
                                                                      "$1$3"
                                                                    ))}
                                                            </span>{" "}
                                                            <br></br>
                                                            <span>
                                                              {"Session 2 : " +
                                                                (timesheet.hearingStartTimeAdditionalUTC &&
                                                                  new Date(
                                                                    timesheet.hearingStartTimeAdditionalUTC.indexOf(
                                                                      "GMT"
                                                                    )
                                                                      ? Utility.convertUTCDateToUserTimeZoneOfPMADecision(
                                                                          timesheet.hearingStartTimeAdditionalUTC
                                                                        )
                                                                      : Utility.convertUTCDateToUserTimeZoneOfPMADecision(
                                                                          new Date(
                                                                            timesheet.hearingStartTimeAdditionalUTC
                                                                          ).toUTCString()
                                                                        )
                                                                  )
                                                                    .toLocaleTimeString()
                                                                    .replace(
                                                                      /([\d]+:[\d]{2})(:[\d]{2})(.*)/,
                                                                      "$1$3"
                                                                    )) +
                                                                " to " +
                                                                (timesheet.hearingEndTimeAdditionalUTC &&
                                                                  new Date(
                                                                    timesheet.hearingEndTimeAdditionalUTC.indexOf(
                                                                      "GMT"
                                                                    )
                                                                      ? Utility.convertUTCDateToUserTimeZoneOfPMADecision(
                                                                          timesheet.hearingEndTimeAdditionalUTC
                                                                        )
                                                                      : Utility.convertUTCDateToUserTimeZoneOfPMADecision(
                                                                          new Date(
                                                                            timesheet.hearingEndTimeAdditionalUTC
                                                                          ).toUTCString()
                                                                        )
                                                                  )
                                                                    .toLocaleTimeString()
                                                                    .replace(
                                                                      /([\d]+:[\d]{2})(:[\d]{2})(.*)/,
                                                                      "$1$3"
                                                                    ))}
                                                            </span>
                                                          </span>
                                                        ) : (
                                                          (timesheet.hearingStartTimeUTC &&
                                                            new Date(
                                                              timesheet.hearingStartTimeUTC.indexOf(
                                                                "GMT"
                                                              )
                                                                ? Utility.convertUTCDateToUserTimeZoneOfPMADecision(
                                                                    timesheet.hearingStartTimeUTC
                                                                  )
                                                                : Utility.convertUTCDateToUserTimeZoneOfPMADecision(
                                                                    new Date(
                                                                      timesheet.hearingStartTimeUTC
                                                                    ).toUTCString()
                                                                  )
                                                            )
                                                              .toLocaleTimeString()
                                                              .replace(
                                                                /([\d]+:[\d]{2})(:[\d]{2})(.*)/,
                                                                "$1$3"
                                                              )) +
                                                          " to " +
                                                          (timesheet.hearingEndTimeUTC &&
                                                            new Date(
                                                              timesheet.hearingEndTimeUTC.indexOf(
                                                                "GMT"
                                                              )
                                                                ? Utility.convertUTCDateToUserTimeZoneOfPMADecision(
                                                                    timesheet.hearingEndTimeUTC
                                                                  )
                                                                : Utility.convertUTCDateToUserTimeZoneOfPMADecision(
                                                                    new Date(
                                                                      timesheet.hearingEndTimeUTC
                                                                    ).toUTCString()
                                                                  )
                                                            )
                                                              .toLocaleTimeString()
                                                              .replace(
                                                                /([\d]+:[\d]{2})(:[\d]{2})(.*)/,
                                                                "$1$3"
                                                              ))
                                                        )}
                                                      </td>
                                                    ) : (
                                                      <td></td>
                                                    )}
                                                    {/* {!props.caseDetails
                                                      .isCommercialCase && (
                                                      <td data-label="Reserved time">
                                                        {Utility.toHoursAndMinutes(
                                                          timesheet.hearingDuration
                                                        )}
                                                      </td>
                                                    )} */}
                                                    {!props.caseDetails
                                                      .isCommercialCase && (
                                                      <td data-label="Review of documents & preparation of decision time">
                                                        {/* {
                                                                                                                        timesheet.revDocTimehours
                                                                                                                    }{" "}
                                                                                                                    Hours{" "}
                                                                                                                    {
                                                                                                                        timesheet.revDocTimeminutes
                                                                                                                    }{" "}
                                                                                                                    Minutes */}
                                                        {timesheet.revDocTimehours
                                                          ? timesheet.revDocTimehours +
                                                            " Hours " +
                                                            timesheet.revDocTimeminutes +
                                                            " Minutes"
                                                          : Utility.toHoursAndMinutes(
                                                              parseFloat(
                                                                timesheet.revDocTimeminutes
                                                              )
                                                            )}
                                                      </td>
                                                    )}
                                                    <td data-label="Total hearing time">
                                                      {getTotalTime(timesheet)}
                                                    </td>
                                                  </tr>
                                                </>
                                              );
                                            }
                                          )}
                                        </tbody>
                                      </table>
                                      <div
                                        style={{
                                          display: caseUnsettled
                                            ? "none"
                                            : "block",
                                        }}
                                      >
                                        Total time spent on this case
                                      </div>
                                      <span
                                        className="font-weight-bold"
                                        style={{
                                          color: "#337AB7",
                                          display: caseUnsettled
                                            ? "none"
                                            : "block",
                                        }}
                                      >
                                        {Utility.toHoursAndMinutes(
                                          props.caseDetails?.caseDuration
                                        )}
                                        {/* {totalDuration} */}
                                      </span>
                                    </div>
                                  </>
                                )}
                              <div>
                                {`The Arbitrator herein, ${
                                  props.caseDetails["hearingOfficerName"]
                                }, affirms, pursuant to CPLR §7507, 
                    that I am the Arbitrator of the Arbitration held on ${Utility.getFulldate(
                      props.caseDetails["hearingDate"]
                    )}, at NAM- VIA VIDEO CONFERENCE, Garden City, NY, pursuant to the agreement of the parties.
                    I issued this Arbitration Decision and Award, dated ${Utility.getdate(
                      dateObj
                    )}, in the matter of 
                    ${props.caseDetails["caseName"]}.`}
                              </div>
                              <p>
                                <span className="float-left">
                                  Hearing Officer:&nbsp;
                                </span>
                                <span className="float-left">
                                  {props.caseDetails["hearingOfficerName"]}{" "}
                                  &nbsp;
                                </span>
                                {/* <div className="col-md-9"> */}
                                <input
                                  type="email"
                                  placeholder="Email"
                                  style={{ width: "25rem" }}
                                  value={HearingOfficerEmailPMA}
                                  onChange={(e) =>
                                    setHearingOfficerEmail(e.target.value)
                                  }
                                  disabled={EDIT_NEUTRAL_EMAIL}
                                ></input>
                              </p>
                            </span>
                          </div>
                          <hr />
                        </div>
                      </div>

                      {/* New Code */}
                      {/* New Code End */}

                      <div>
                        <div
                          className="text-left"
                          style={{ fontWeight: "600", marginTop: "20px" }}
                        >
                          Upload Decision and/or supporting documents
                        </div>
                        <p style={{ fontSize: "12px", textAlign: "left" }}>
                          Please upload pdf/doc/.docx format only. Max 10MB file
                          size allowed.
                        </p>

                        <div className="">
                          <div
                            className="row"
                            style={{ margin: "auto", marginLeft: "10.6rem" }}
                          >
                            {/* {ifnotMarkAsPMA && (
                          <label style={{ color: "red" }}>
                            Please mark at least one file as Decision
                          </label>
                        )} */}
                            {switchUploads === false &&
                              isuploaderrorMessage && (
                                <div className="">
                                  {uploaderrorMessage && (
                                    <span className="action">
                                      <i
                                        style={{ color: "red" }}
                                        className="fa fa-warning"
                                      ></i>
                                    </span>
                                  )}
                                  <label
                                    style={{ color: "red" }}
                                    className="error-message error"
                                  >
                                    {uploaderrorMessage}
                                  </label>
                                </div>
                              )}
                          </div>
                          {switchUploads === false && isuploaderrorMessage1 && (
                            <div className="">
                              <label
                                style={{ color: "red", marginLeft: "5px" }}
                                className="error-message error "
                              >
                                {uploaderrorMessage1}
                              </label>
                            </div>
                          )}
                          {/* <div style={{ marginBottom: '0.75rem', display: switchUploads ? "none" : "block" }} > */}
                          {switchUploads === false && (
                            <div
                              className={
                                decisionFileAvailable &&
                                !isDocuSigned &&
                                pmaDetails.status === "sent"
                                  ? "disableditem"
                                  : isFormdisabled
                                  ? "disableditem"
                                  : ""
                              }
                            >
                              {props.caseDetails["isPaid"] ? (
                                <></>
                              ) : !Utility.IsCaseDecision(
                                  props.caseDetails["caseStatus"]
                                ) && TimesheetNotSubmitted ? (
                                <div className="row">
                                  <div style={{ display: "flex" }}>
                                    <span style={{ color: "red" }}>
                                      You must submit timesheet before
                                      proceeding with the Decision submission.
                                    </span>
                                  </div>
                                </div>
                              ) : (
                                <></>
                              )}
                              {console.log(
                                decisionFileAvailable,
                                "decisionFileAvailable"
                              )}
                              {console.log(isDocuSigned, "isDocuSigned")}
                              {console.log(
                                pmaDetails.status,
                                "pmaDetails.status"
                              )}
                              {console.log(isFormdisabled, "isFormdisabled")}
                              <FileUploader
                                disabled={
                                  decisionFileAvailable &&
                                  !isDocuSigned &&
                                  pmaDetails.status === "sent"
                                    ? true
                                    : isFormdisabled
                                }
                                name="file_uploader"
                                onDropHandler={onDropHandler}
                                componentType="DRAG_DROP_BROWSE"
                                acceptFileTypes={[
                                  "application/pdf",
                                  "application/msword",
                                  "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
                                ]}
                                dropTitle="Drag and drop files here to"
                                dropSubTitle="Choose File"
                                AllowedFileTypesErrorMessage={
                                  "File type not allowed. Allowed file types are .pdf and .doc/.docx"
                                }
                                maxFileSize={10000000} //// 10mb
                                onFileRemove={onFileRemove}
                                maxFileSizeErrorMessage={
                                  "Max allowed file size is 10MB"
                                }
                                showProgressBar={{ show: true }}
                                renderUploadedFilesItem={(fileObj, index) =>
                                  renderUploadedFilesItem(fileObj, index)
                                }
                                onRef={(ref) => (childRef.current = ref)}
                                regex={/^[A-Za-z0-9@ ]+$/}
                                dropbackgroundClass="customDrop"
                                restrictMultipleFiles={false}
                                layout="2column"
                              />
                            </div>
                          )}

                          {/* </div> */}
                          {/* this code is commented out because of no need to show consolidated decision file */}
                          {/* {switchUploads &&
                        (response === null ||
                          response === undefined ||
                          response === "") && (
                          <>
                            <div
                              className="row"
                              style={{
                                borderBottom: "1px solid",
                                borderBottomColor: "lightgrey",
                                borderTop: "1px solid",
                                borderTopColor: "lightgrey",
                              }}
                            >
                              <div className="col-md-4 col-3 text-left">Decision</div>
                              <div className="col-md-4 col-9 response-submission">
                                <div className="">
                                  <span>Decision not submitted</span>
                                </div>
                              </div>
                            </div>
                          </>
                        )} */}

                          {/* {switchUploads && response && (
                        <>
                          <div
                            className="row"
                            style={{
                              borderBottom: "1px solid",
                              borderBottomColor: "lightgrey",
                            }}
                          >
                            <div className="col-sm-4 text-left">Decision</div>
                            <div className="col-sm-5 response-submission text-left">
                              <span className="action">
                                {" "}
                                <i
                                  style={{ color: "#DC1D00" }}
                                  className="fa fa-file-pdf-o"
                                ></i>
                              </span>
                              <span className="file-name">{`${props.caseDetails["caseReference"]}.pdf`}
                              &nbsp; &nbsp; &nbsp; &nbsp; 
                                      {Utility.getdate(pmaDetails.updatedDate)}
                              </span>
                              <span
                                className="action"
                                onClick={() => previewResponse(response)}
                              >
                                <i className="fa fa-eye"></i>
                              </span>

                              <span
                                className="action"
                                onClick={() => {
                                  downloadResponse(
                                    response,
                                    `${props.caseDetails["caseReference"]}.pdf`
                                  );
                                }}
                              >
                                <i className="fa fa-download"></i>
                              </span>
                              <span className="action" onClick={
                                () => {
                                  // deleteResponse
                                  setOpenConfirmDialog(true);
                                  setCaseReference(true)
                                  setDeleteMessage(`Are you sure you want to delete this file: ${props.caseDetails["caseReference"]}.pdf ?`)

                                }

                              }>
                                <i className="fa fa-trash"></i>
                              </span>
                            </div>
                          </div>
                        </>
                      )} */}

                          {switchUploads && additionalFile && (
                            <div className="row">
                              <div className="col-md-4 text-left">
                                Additional Document(s)
                              </div>
                              <div className="col-md-4 text-left">
                                Date Uploaded
                              </div>
                              <div className="col-md-4 text-left">
                                Action(s)
                              </div>
                              <hr
                                style={{ width: "90%" }}
                                className="solid"
                              />
                              {additionalFile !== null &&
                              additionalFile.length > 0 ? (
                                additionalFile?.map((item, index) => {
                                  return (
                                    markAsPmaFile !== item.name && (
                                      <div className="row">
                                        <div
                                          className="col-md-4 text-left"
                                          style={{
                                            color:
                                              item?.fileName ===
                                              markAsPmaDecisioFile
                                                ? "blue"
                                                : "gray",
                                          }}
                                        >
                                          <span className="action">
                                            {" "}
                                            <i
                                              style={{
                                                color:
                                                  item?.fileName?.indexOf(
                                                    ".pdf"
                                                  ) !== -1
                                                    ? "#DC1D00"
                                                    : "blue",
                                              }}
                                              className={
                                                item?.fileName?.indexOf(
                                                  ".pdf"
                                                ) !== -1
                                                  ? "fa fa-file-pdf-o"
                                                  : "fa fa-file-word-o"
                                              }
                                            ></i>
                                          </span>
                                          <span className="file-name">
                                            &nbsp; &nbsp;{item.fileName}
                                          </span>
                                        </div>
                                        {/* <span className="action" onClick={(e) => removeFile(e, item, index)}> */}
                                        <div className="col-md-4 text-left">
                                          &nbsp; &nbsp;
                                          {Utility.getdate(item.updateDate)}
                                        </div>
                                        <div className="col-md-4 text-left">
                                          &nbsp; &nbsp;
                                          <span
                                            className="action"
                                            onClick={() =>
                                              viewResponse(item.fileName)
                                            }
                                          >
                                            <i className="fa fa-eye"></i>
                                          </span>
                                          &nbsp; &nbsp;
                                          <span
                                            className="action"
                                            onClick={() => {
                                              downloadResponse(item.fileName);
                                            }}
                                          >
                                            <i className="fa fa-download"></i>
                                          </span>{" "}
                                          &nbsp; &nbsp;
                                          {!(
                                            decisionFileAvailable &&
                                            !isDocuSigned
                                          ) && (
                                            <span
                                              className="action"
                                              onClick={() => {
                                                // deleteAdditionalResponse(item.fileName);
                                                setOpenConfirmDialog(true);
                                                setAdditionalFileRemove(
                                                  item.fileName
                                                );
                                                setDeleteMessage(
                                                  `Are you sure you want to delete this file: ${item.fileName}`
                                                );
                                              }}
                                            >
                                              <i className="fa fa-trash"></i>
                                            </span>
                                          )}
                                        </div>
                                      </div>
                                    )
                                  );
                                })
                              ) : (
                                <div className="col-md-4">
                                  No additional document(s) are found
                                </div>
                              )}
                            </div>
                          )}

                          {!switchUploads && (
                            <div style={{ textAlign: "left" }}>
                              <button
                                name="docSubmit"
                                disabled={
                                  !isFileUpload ||
                                  TimesheetNotSubmitted ||
                                  isFormdisabled ||
                                  decisionFileAvailable
                                }
                                className={
                                  isFileUpload &&
                                  !TimesheetNotSubmitted &&
                                  !isFormdisabled &&
                                  !decisionFileAvailable
                                    ? "pmaButton btn-primary"
                                    : "pmaButtonDisabled btn-primary"
                                }
                                onClick={docSubmitHandler}
                              >
                                Upload
                              </button>
                            </div>
                          )}
                        </div>
                      </div>
                      {/* Preview Button */}
                      {(props.hearingDetails?.rowData?.massFileId === null ||
                        selectedCaseForDecisionApply !== null) && (
                        <div
                          className=""
                          style={{
                            textAlign: "left",
                            borderTop: "0px !important",
                            marginTop: "1rem",
                          }}
                        >
                          {(Utility.IsCaseClosed(
                            props.caseDetails["caseStatus"]
                          ) === true &&
                            (decisionFileAvailable !== null ||
                              decisionFileAvailable !== "" ||
                              decisionFileAvailable !== undefined ||
                              decisionFileAvailable === true)) ||
                          (Object.keys(plainTiffDefentError).length > 0 &&
                            btnHandle === false &&
                            caseUnsettled === false) ||
                          (decisionFileAvailable &&
                            !isDocuSigned &&
                            pmaDetails.status === "sent") ? (
                            <button
                              name="submitEsign"
                              disabled={true}
                              onClick={submitHandler}
                              className={"pmaButtonDisabled btn-primary"}
                            >
                              Preview e-Signature Document
                            </button>
                          ) : (
                            <button
                              name="submitEsign"
                              disabled={
                                isFormdisabled ||
                                Utility.IsCaseDecision(
                                  selectedCaseForDecisionApply
                                    ? matchedCase?.caseStatus
                                    : props.caseDetails["caseStatus"]
                                )
                                  ? true
                                  : props.caseDetails["isPaid"]
                                  ? false
                                  : file && file?.length > 0
                                  ? true
                                  : !TimesheetNotSubmitted
                                  ? (isFormSubmit === true && isValid) ||
                                    caseUnsettled ||
                                    decisionFileAvailable
                                    ? false
                                    : true
                                  : true
                              }
                              onClick={submitHandler}
                              className={
                                isFormdisabled ||
                                Utility.IsCaseDecision(
                                  props.caseDetails["caseStatus"]
                                )
                                  ? "pmaButtonDisabled btn-primary"
                                  : props.caseDetails["isPaid"]
                                  ? "pmaButton btn-primary"
                                  : file && file?.length > 0
                                  ? "pmaButtonDisabled btn-primary"
                                  : !TimesheetNotSubmitted
                                  ? (isFormSubmit === true && isValid) ||
                                    caseUnsettled ||
                                    decisionFileAvailable
                                    ? "pmaButton btn-primary"
                                    : "pmaButtonDisabled btn-primary"
                                  : "pmaButtonDisabled btn-primary"
                              }
                            >
                              Preview e-Signature Document
                            </button>
                          )}
                        </div>
                      )}

                      {/* {console.log(first)} */}

                      {getShowApplyTemplate() && (
                        <div>
                          <button
                            className="btn-primary"
                            onClick={onClickApplyDecisionTemplate}
                          >
                            Apply Decision Template
                          </button>
                        </div>
                      )}

                      <br></br>
                      <br></br>
                    </>
                  )}
                </div>
              </>
            </>
          </div>
        </div>

        {isdisplayAlertError === true && (
          <AlertDialog
            isdisplayAlert={false}
            isdisplayAlertError={isdisplayAlertError}
            updatedisplayAlertError={updatedisplayAlert}
          ></AlertDialog>
        )}

        {openConfirmDialog && (
          <AlertDialog
            isdisplayAlert={false}
            isdisplayAlertError={false}
            openConfirmDialog={openConfirmDialog}
            closeConfirmDialog={closeConfirmDialog}
            deleteMessage={deleteMessage}
          ></AlertDialog>
        )}

        <div style={{ display: "none" }}>
          <DecisionTemplate
            caseDetails={props.caseDetails}
            pmaDetails={pmaDetails}
            caseUnsettled={caseUnsettled}
            hearingCode={hearingCode}
            attendies={attendies}
            totalDuration={totalDuration}
            hearingOfficerEmail={HearingOfficerEmailPMA}
            totalSum={totalSum}
          />
        </div>
      </LoadingOverlay>
    </div>
  );
}
